import React, { useRef, useState } from "react";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { isLarge, isRegular } from "../../pages/CategoryPage";

const CoverPictureManager = ({
  coverPicture,
  setCoverPicture,
  setCoverPictureBlob,
  coverPictureBlob,
  setPreviewURL,
  previewURL,
  setIsLoaded,
}) => {
  const inputRef = useRef(null);

  const uploadFile = (input, file) => {
    setIsLoaded(false);
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        console.log("============== upload -------",blob.signed_id);
        setCoverPictureBlob(blob.signed_id);
        setIsLoaded(true);
      }
    });
  };
  const handleFileUpload = (files) => {
    files.forEach((file, index) => {
      const dataURL = URL.createObjectURL(file);
      setPreviewURL(dataURL);
      uploadFile(inputRef, file, index);
    });
  };
  if (previewURL) {
    return (
      <>
        <div className="flex text-sm text-gray-600">
          <Dropzone
            onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
            maxFiles={1}
          >
            {({ getRootProps, getInputProps }) => (
              <label
                htmlFor="cover_picture--upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-skin-600 hover:text-skin-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-skin-500"
              >
                <div {...getRootProps()}>
                  <input ref={inputRef} {...getInputProps()} />
                  {!previewURL && (
                    <p>Glisser la photo, ou cliquez pour la selectionner</p>
                  )}
                  <img
                    id={"cover-picture-preview"}
                    src={previewURL}
                    alt={"Cover picture"}
                  />
                </div>
              </label>
            )}
          </Dropzone>
        </div>
      </>
    );
  }

  return (
    <div className="space-y-1 text-center">
      <Dropzone
        onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
        maxFiles={1}
      >
        {({ getRootProps, getInputProps }) => (
          <label
            htmlFor="cover_picture--upload"
            className="relative cursor-pointer bg-white rounded-md font-medium text-skin-600 hover:text-skin-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-skin-500"
          >
            <div {...getRootProps()}>
              <input ref={inputRef} {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
              <em>(1 fichier maxi )</em>
              {coverPicture && (
                <img
                  id={"cover-picture-preview"}
                  src={coverPicture}
                  alt={"Cover picture"}
                />
              )}
            </div>
          </label>
        )}
      </Dropzone>
    </div>
  );
};

export default CoverPictureManager;

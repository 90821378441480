import React from "react";
import ReactDOM from "react-dom";
import App from "../src/App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "../src/providers/authProvider";
import LogRocket from "logrocket";
document.addEventListener("turbolinks:load", () => {
  LogRocket.init("mister-e/vasseur");
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

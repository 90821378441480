import { Link } from "react-router-dom";
import React from "react";
import _ from "lodash";

const Breadcrumbs = ({ breadCrumbs }) => {
  return (
    <nav className="flex mb-12 hidden sm:block" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center">
        {breadCrumbs.map((breadcrumb) => {
          return (
            <li key={_.uniqueId("breadcrumbs__")}>
              <div className="flex items-center">
                <Link
                  to={breadcrumb.link}
                  className=" text-24 font-medium text-skin hover:text-black font-freight uppercase whitespace-pre"
                >
                  {breadcrumb.label}
                </Link>
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

import GenericLayout from "../layouts/GenericLayout";
import React from "react";
import { useParams } from "react-router-dom";
import { useInspiration } from "../_services/inspiration-service";
import Loading from "../components/utils/Loading";
import Error from "../components/Error";
import { useRealisation } from "../_services/realisation-service";
import Breadcrumbs from "../components/breadcrumbs";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";
import RealisationProductsSlider from "./RealisationProductsSlider";
import { DateTime } from "luxon";
import { NewsBody } from "../components/News/NewsBody";
import styled from "styled-components";

const RealisationPage = () => {
  const CustomNewsBody = styled(NewsBody)`
    color: red;
    border-radius: 20px;
  `;

  const { realisation_id } = useParams();

  const { realisation, isError, isLoading } = useRealisation(realisation_id);
  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  const {
    title,
    description,
    name,
    cover_picture_url,
    release_date,
    catch_line,
  } = realisation.data.attributes;
  const products = getAllIncludedResourcesForAGivenType(realisation, "product");

  return (
    <GenericLayout>
      <div className="xl:w-vw-5/6 xl:mx-auto mx-4 flex sm:mb-10 mb-4 sm:flex-row flex-col justify-between">
        <div className="xl:w-vw-1/3 w-full xl:mx-auto">
          <div
            className="imgs aspect-w-1 aspect-h-1 rounded-md lg:rounded-r-none bg-center bg-cover"
            style={{ backgroundImage: `url(${cover_picture_url})` }}
          />
        </div>

        <div className="xl:bg-blancfonce xl:w-vw-1/2 xl:mb-0 mb-4 xl:ml-0 sm:ml-4 rounded-br-md rounded-tr-md">
          <div className="xl:pl-20 xl:mt-20">
            {/*<p className="font-helvetica text-14 text-golden-500">Recette</p>*/}
            <h2 className="font-fraunces sm:text-40 text-32 text-black font-bold xl:mt-8 sm:mt-4 mt-0 first-letter:uppercase">
              {name}
            </h2>
            <h3 className="font-fraunces sm:text-lg text-base text-justify text-gray-700 font-bold xl:mt-8 sm:mt-4 mt-0 max-w-xl ">
              {catch_line}
            </h3>
          </div>
          <hr className="xl:block hidden border border-black w-32 mt-20" />
          <div className="font-helvetica font-bold text-12 text-gris xl:ml-20 ml-0 mt-4">
            <p>Par l'équipe Vasseur</p>
            <p>le {DateTime.fromISO(release_date).toFormat("dd/MM/yyyy")}</p>
          </div>
        </div>
      </div>

      <div className="xl:w-vw-1/2 xl:mx-auto mx-4 flex sm:mb-10 mb-4">
        <div className="flex flex-col w-full">
          <hr className="border border-gris mt-2" />
          <CustomNewsBody content={description} />
          <hr className="border border-golden-500 w-32 mt-16 mb-4" />
        </div>
      </div>
    </GenericLayout>
  );
};

export default RealisationPage;

import ProductCard from "./ProductCard";
import _ from "lodash";
import React from "react";

const ProductGrid = ({ products }) => {
  if (products.length === 0) {
    return (
      <div className={"mx-auto px-4 max-w-screen-2xl"}>
        Aucun produit dans cette catégorie pour le moment
      </div>
    );
  }
  return (
    <div className="flex flex-wrap justify-center my-10 gap-8 grid sm:grid-cols-4 mx-auto px-4 max-w-screen-2xl">
      {products.map(({ attributes }) => {
        const { name, id, thumb_type, cover_picture_url, reference } =
          attributes;
        return (
          <ProductCard
            key={_.uniqueId(`product_${id}`)}
            name={name}
            id={id}
            thumb_type={thumb_type}
            cover_picture_url={cover_picture_url}
            reference={reference}
          />
        );
      })}
    </div>
  );
};

export default ProductGrid;

import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export const createInspiration = (data, callback) => {
  Api.post(
    `${API_V1_BASE_URL}/inspirations`,
    { inspiration: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    // .then(category => uploadFile(blobSignedID, category))
    .then(callback);
};
export const updateInspiration = (id, data, callback) => {
  Api.put(
    `${API_V1_BASE_URL}/inspirations/${id}`,
    { inspiration: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((res) => res.data)
    .then(callback);
};
export const deleteInspiration = (id, callback) => {
  Api.delete(`${API_V1_BASE_URL}/inspirations/${id}`).then(callback);
};

export const useInspirations = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/inspirations/`,
    getFetcher
  );

  return {
    inspirations: data,
    isError: error,
    isLoading: !error && !data,
  };
};

export const useInspiration = (inspiration_id) => {
  const { data, error } = useSWR(
    () =>
      inspiration_id
        ? `${API_V1_BASE_URL}/inspirations/${inspiration_id}`
        : null,
    getFetcher
  );

  return {
    inspiration: data,
    isError: error,
    isLoading: !error && !data,
  };
};

export const useLastInspiration = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/last_inspirations`,
    getFetcher
  );

  return {
    inspiration: data,
    isError: error,
    isLoading: !error && !data,
  };
};

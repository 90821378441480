import React, { useEffect } from "react";
import Homepage from "./pages/Homepage";
import { Route, Routes } from "react-router-dom";
import ProductsPage from "./pages/ProductsPage";
import LoginPage from "./pages/admin/Login";
import { PrivateRoute } from "./components/PrivateRoute";
import Api from "./_helpers/api";
import AdminCategoriesPage from "./pages/admin/categories/AdminCategoriesPage";
import AdminUniversesPage from "./pages/admin/universes/AdminUniversesPage";
import AdminInspirationsPage from "./pages/admin/inspirations/AdminInspirationsPage";
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import AdminContactPage from "./pages/admin/contacts/AdminContactPage";
import AdminProductsPage from "./pages/admin/products/AdminProductsPage";
import AdminProductAddEditPage from "./pages/admin/products/AdminProductAddEditPage";
import { useAuth } from "./hooks/useAuth";
import AdminCategoriesAddEditPage from "./pages/admin/categories/AdminCategoriesAddEditPage";
import AdminUniversesAddEditPage from "./pages/admin/universes/AdminUniversesAddEditPage";
import AdminInspirationAddEditPage from "./pages/admin/inspirations/AdminInspirationAddEditPage";
import AdminMaterialAddEditPage from "./pages/admin/materials/AdminMaterialAddEditPage";
import AdminMaterialsPage from "./pages/admin/materials/AdminMaterialsPage";
import ProductPage from "./pages/ProductPage";
import UniversePage from "./pages/UniversePage";
import AdminRealisationsPage from "./pages/admin/realisations/AdminRealisationsPage";
import AdminRealisationAddEditPage from "./pages/admin/realisations/AdminRealisationAddEditPage";
import InspirationPage from "./pages/InspirationPage";
import RealisationPage from "./pages/RealisationPage";
import InspirationsPage from "./pages/InspirationsPage";
import Presentation from "./pages/Presentation";
import Contact from "./pages/Contact";
import AdminContactShowPage from "./pages/admin/contacts/AdminContactShowPage";
import CategoryPage from "./pages/CategoryPage";
import { Helmet } from "react-helmet";
import MentionsLegales from "./pages/MentionsLegales";
import ReferencePage from "./pages/ReferencePage";
import Categories from "./components/Categories";
import News from "./components/News";
import LogRocket from "logrocket";

const App = () => {
  const { sessionAuthHeader } = useAuth();

  useEffect(() => {
    LogRocket.init("mister-e/vasseur");
    console.log("load");

    Api.defaults.headers.common["X-CSRF-TOKEN"] =
      document.querySelector("[name=csrf-token]").content;

    console.log(document.querySelector("[name=csrf-token]").content);

    if (sessionAuthHeader) {
      Api.defaults.headers.common["X-ADMIN-EMAIL"] = sessionAuthHeader.email;
      Api.defaults.headers.common["X-ADMIN-TOKEN"] = sessionAuthHeader.token;
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Passionnément charcutier depuis 1960 !"
        />
      </Helmet>
      <Routes>
        {/* <Route path={"/"} exact element={<Homepage />} /> */}
        <Route path={"produits"} element={<ProductsPage />} />
        <Route path={"/nos-gammes-produits/"} element={<Categories />} />
        <Route
          path={"/nos-gammes-produits/:category_id/:category_slug"}
          element={<CategoryPage />}
        />
        <Route path={"/nos-actualites"} element={<News />} />
        <Route path={"/produit/:product_id"} element={<ProductPage />} />
        {/* <Route path={"/la-maison-vasseur"} element={<Presentation />} /> */}
        <Route path={"/univers/:univers_id"} element={<UniversePage />} />
        <Route path={"/inspirations"} element={<InspirationsPage />} />
        <Route path={"/references"} element={<ReferencePage />} />
        <Route path={"/mentions-legales"} element={<MentionsLegales />} />
        <Route
          path={"/inspirations/:inspiration_id"}
          element={<InspirationPage />}
        />
        <Route
          path={"/nos-actualites/:realisation_id"}
          element={<RealisationPage />}
        />
        <Route path={"/presentation"} element={<Presentation />} />
        {/* <Route path={"/services"} element={<Services />} /> */}
        {/* <Route path={"/services/showroom"} element={<Showroom />} /> */}
        {/* <Route */}
        {/* path={"/services/entrepot-stockage-atelier"} */}
        {/* element={<EntrepotStockageAtelier />} */}
        {/* /> */}
        {/* <Route
          path={"/services/livraison-montage"}
          element={<LivraisonMontage />}
        /> */}
        <Route path={"contact"} element={<Contact />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products"
          element={
            <PrivateRoute>
              <AdminProductsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products/new"
          element={
            <PrivateRoute>
              <AdminProductAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products/:product_id"
          element={
            <PrivateRoute>
              <AdminProductAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <PrivateRoute>
              <AdminCategoriesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories/new"
          element={
            <PrivateRoute>
              <AdminCategoriesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories/:category_id"
          element={
            <PrivateRoute>
              <AdminCategoriesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/univers"
          element={
            <PrivateRoute>
              <AdminUniversesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/universes/new"
          element={
            <PrivateRoute>
              <AdminUniversesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/universes/:universe_id"
          element={
            <PrivateRoute>
              <AdminUniversesAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/inspirations"
          element={
            <PrivateRoute>
              <AdminInspirationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/inspirations/new"
          element={
            <PrivateRoute>
              <AdminInspirationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/inspirations/:inspiration_id"
          element={
            <PrivateRoute>
              <AdminInspirationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/realisations"
          element={
            <PrivateRoute>
              <AdminRealisationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/realisations/new"
          element={
            <PrivateRoute>
              <AdminRealisationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/realisations/:realisation_id"
          element={
            <PrivateRoute>
              <AdminRealisationAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/packing"
          element={
            <PrivateRoute>
              <AdminMaterialsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/packing/new"
          element={
            <PrivateRoute>
              <AdminMaterialAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/materials/:material_id"
          element={
            <PrivateRoute>
              <AdminMaterialAddEditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/prospects"
          element={
            <PrivateRoute>
              <AdminUsersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/contacts"
          element={
            <PrivateRoute>
              <AdminContactPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/contacts/:contact_id"
          element={
            <PrivateRoute>
              <AdminContactShowPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../../_helpers/api";
import { Tab } from "@headlessui/react";
import { DirectUpload } from "@rails/activestorage";
import classNames from "classnames";
import SelectCategories from "../../../components/forms/SelectCategories";
import { useProduct } from "../../../_services/product-services";
import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";
import SelectMaterials from "../../../components/forms/SelectMaterials";
import ProductCard from "../../../components/products/ProductCard";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import PicturesManager from "../../../components/utils/PicturesManager";
import DocumentManager from "../../../components/utils/DocumentManager";
import PreviewCard from "../PreviewCard";

const AdminProductAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [result, setResult] = useState("");
  const [productCategories, setProductCategories] = useState([]);
  const [productMaterials, setProductMaterials] = useState([]);
  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");
  const [previewURLs, setPreviewURLs] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [picturesBlobs, setPicturesBlobs] = useState([]);

  const [filePDF, setFilePDF] = useState();
  const [filePDFBlob, setFilePDFBlob] = useState({});

  const [fileSketchup, setFileSketchup] = useState();
  const [fileSketchupBlob, setFileSketchupBlob] = useState({});

  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();

  let { product_id } = useParams();
  let isEditMode = !!product_id;

  const thumbTypeOptions = [
    { value: "large", label: "Horizontale - 16/9" },
    { value: "regular", label: "verticale - 8/9" },
  ];
  const onSubmit = (data) => {
    return isEditMode ? updateProduct(product_id, data) : createProduct(data);
  };
  const updateProduct = (id, data) => {
    let productCategoriesIDS = productCategories.map(
      (productCategory) => productCategory.id
    );
    Api.patch(
      `/api/v1/products/${id}`,
      JSON.stringify({
        product: {
          ...data,
          cover_picture: coverPictureBlob,
          doc_pdf: filePDFBlob,
          file_sketchup: fileSketchupBlob,
          pictures: picturesBlobs,
          category_ids: productCategoriesIDS,
          material_ids: productMaterials.map((material) => {
            return material.id;
          }),
        },
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.data)
      .then(() => navigate("/admin"));
  };
  const createProduct = (data) => {
    let productCategoriesIDS = productCategories.map(
      (productCategory) => productCategory.id
    );
    Api.post(
      "/api/v1/products",
      JSON.stringify({
        product: {
          ...data,
          cover_picture: coverPictureBlob,
          pictures: picturesBlobs,
          doc_pdf: filePDFBlob,
          category_ids: productCategoriesIDS,
          material_ids: productMaterials.map((material) => {
            return material.id;
          }),
        },
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((response) => response.data)
      .then(() => navigate("/admin"));
  };
  const handleDelete = () => {
    Api.delete(`/api/v1/products/${product_id}`).then((result) => {
      navigate("/admin");
    });
  };

  const { product, isLoading, isError } = useProduct(product_id);
  const fields = [
    "reference",
    "name",
    "structure",
    "properties",
    "width",
    "height",
    "depth",
    "materials",
    "description",
    "slug",
    "weight",
    "release_date",
    "more_details",
    "thumb_type",
    "video_url",
  ];

  useEffect(() => {
    if (!!product && product_id) {
      fields.forEach((field) =>
        setValue(field, product.data.attributes[field])
      );
      setProductCategories(
        getAllIncludedResourcesForAGivenType(product, "category")
      );
      setProductMaterials(
        getAllIncludedResourcesForAGivenType(product, "material")
      );

      setCoverPicture(product.data.attributes.cover_picture_url);
      setPreviewURL(product.data.attributes.cover_picture_url);

      setPictures(product.data.attributes.pictures_data);
      setFilePDF(product.data.attributes.doc_pdf_data);
      setFileSketchup(product.data.attributes.file_sketchup_data);
    }
  }, [product]);

  if (isLoading && product_id) {
    return <div>Product loading...</div>;
  }

  if (isError) {
    return <div> loading failed ...</div>;
  }

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className={"flex flex-row justify-between items-center"}>
          <h3 className={"text-3xl py-5"}>
            {isEditMode ? "Edition" : "Création"} un nouveau produit
          </h3>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Informations générales
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Ces informations seront visibles sur la page de description du
                  produit.
                </p>
              </div>
              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Nom
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("name")}
                      className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      autoComplete="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Caractéristiques
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("description", { required: false })}
                      id="description"
                      name="description"
                      rows={5}
                      className="max-w-lg shadow-sm block w-full focus:ring-skin-500 focus:border-skin-500 sm:text-sm border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Description succinte du produit.
                    </p>
                  </div>
                </div>
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Photos
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Utilisez des photos présentant uniquement la réalisation
                      en question.
                    </p>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="cover-photo"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Photo de couverture
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <CoverPictureManager
                          coverPicture={coverPicture}
                          setCoverPicture={setCoverPicture}
                          coverPictureBlob={coverPictureBlob}
                          setCoverPictureBlob={setCoverPictureBlob}
                          setPreviewURL={setPreviewURL}
                          previewURL={previewURL}
                          setIsLoaded={setIsLoaded}
                          thumb_type={watch("thumb_type")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Conditionnements
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Quels conditionnements disponibles ?
                    </p>
                  </div>
                  <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                    <div className="pt-6 sm:pt-5">
                      <div role="group" aria-labelledby="label-notifications">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                          <div>
                            <div
                              className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                              id="label-notifications"
                            >
                              Conditionnements
                            </div>
                          </div>
                          <div className="sm:col-span-2">
                            <div className="max-w-lg">
                              <div className="mt-4 space-y-4">
                                <div className="space-y-6">
                                  <SelectMaterials
                                    setSelectedOptions={setProductMaterials}
                                    selectedOptions={productMaterials}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                  <div className="pt-6 sm:pt-5">
                    <div role="group" aria-labelledby="label-notifications">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                        <div>
                          <div
                            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                            id="label-notifications"
                          >
                            Gammes de produits
                          </div>
                        </div>
                        <div className="sm:col-span-2">
                          <div className="max-w-lg">
                            <div className="mt-4 space-y-4">
                              <div className="space-y-6">
                                <SelectCategories
                                  productCategories={productCategories}
                                  setProductCategories={setProductCategories}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-5 flex flex-row justify-between">
                  <div>
                    <span
                      onClick={handleDelete}
                      className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
                    >
                      Supprimer le produit
                    </span>
                  </div>
                  <div className="flex justify-end">
                    <Link
                      to={"/admin/"}
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
                    >
                      Annuler
                    </Link>
                    <button
                      disabled={!isLoaded}
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-skin-600 hover:bg-skin-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
                    >
                      {isLoaded
                        ? isEditMode
                          ? "Sauvegarder les modifications"
                          : "Créer un nouveau produit"
                        : "Chargement de la photo en cours"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={"flex flex-col w-1/2"}>
        <div
          className={"sticky top-0 relative p-4 m-4 bg-beige-400 rounded-lg"}
        >
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la vignette de présentation
          </h3>
          <div className={"grid grid-cols-2"}>
            <PreviewCard
              reference={watch("name")}
              cover_picture_url={previewURL}
              thumb_type={"large"}
              // name={watch("description")}
              readOnly={true}
              id={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminProductAddEditPage;

import _ from "lodash";

export const getSpecificIncludedRelationship = (
  data,
  type,
  matchingTypeRelationshipTypeData
) => {
  if (matchingTypeRelationshipTypeData) {
    return _.filter(data.included, (includeObject) => {
      return (
        includeObject.type === type &&
        matchingTypeRelationshipTypeData.find(
          (el) => el.id === includeObject.id
        )
      );
    })
      .map(({ attributes }) => {
        return attributes.name;
      })
      .join(" ,");
  }
};

export const getAllIncludedResourcesForAGivenType = (data, type) => {
  return _.filter(data.included, (includeObject) => {
    return includeObject.type === type;
  }).map((includedResource) => includedResource);
};

import React from "react";

const Header = ({ title }) => {
  return (
    <h1
      className={
        "text-center uppercase sm:text-72 text-32 mt-8 sm:mb-16 mb-10 font-freight stroke-golden-500 tracking-wider"
      }
    >
      {title}
    </h1>
  );
};

export default Header;

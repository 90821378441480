import React from "react";
import { Link } from "react-router-dom";
import UE from "../../../assets/images/UE.jpg";
import HDF from "../../../assets/images/HDF.jpg";
import HDF2 from "../../../assets/images/HDF2.jpg";
import logo from "../../../assets/images/logo.png";
import { DateTime } from "luxon";
import { uniqueId } from "lodash";
import { useCategories } from "../_services/category-services";
import Loading from "./utils/Loading";
import Error from "./Error";

const Footer = () => {
  const { categories, isLoading, isError } = useCategories();

  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  return (
    <footer className="bg-skin-500 lg:mx-0 lg:mb-0 mx-4 mb-4">
      <div className="w-vw-2/3 xl:flex hidden flex-col mx-auto pt-12 pb-8">
        <div className="flex flex-row justify-between w-full">
          <div>
            <img className="w-64" src={logo} alt={"vasseur"} />
          </div>
          <div>
            <ul className="text-20 font-helvetica text-black">
              <li className="uppercase font-fraunces font-semibold text-24 mb-2">
                notre gamme de produit
              </li>
              {categories.data.map((category, index) => {
                return (
                  <li key={uniqueId("footer_category_link--")}>
                    <Link
                      to={`/nos-gammes-produits/${category.id}/${category.attributes.name}`}
                      className={"leading-3"}
                    >
                      {" "}
                      {category.attributes.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <ul className="text-20 font-helvetica text-black">
              <li className="uppercase font-fraunces font-semibold text-24 mb-2">
                une question ?
              </li>
              <li>Téléphone: 03 21 53 42 65</li>
              <li>Fax: 03 21 53 41 18</li>
              <li>
                SAS VASSEUR <br />
                Rue de la Perelle <br />
                62620 Ruitz
              </li>
            </ul>
          </div>
        </div>

        <hr className="border-2 border-golden-500 w-full my-8" />

        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-center space-x-4 mx-auto">
            <img className="h-24" src={UE} alt={""} />
            <img className="h-24" src={HDF} alt={""} />
            <img className="h-24" src={HDF2} alt={""} />
          </div>
          <div className={"mx-auto"}>
            <p className="font-helvetica font-medium text-16 text-center">
              CE PROJET EST CO-FINANCÉ PAR LE FONDS SOCIAL EUROPÉEN
            </p>
            <p className="font-helvetica text-12 text-center">
              Financement dans le cadre de la réponse de l’Union à la pandémie
              de COVID-19
            </p>
          </div>
        </div>
        <hr className="border-2 border-golden-500 w-full mt-8 mb-4" />
        <div className={"mx-auto"}>
          <div className="text-center text-16 space-x-4 inline-flex justify-center">
            <span>
              @ {DateTime.now().toFormat("yyyy")} Charcuterie Vasseur - Tous
              droits réservés
            </span>
            <ul className={"inline-flex space-x-4"}>
              <li>Mentions légales</li>
              <li>Plan du site</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="m-4 xl:hidden flex flex-col">
        <div className="mx-auto my-8">
          <img className="w-48 sm:w-64" src={logo} alt={"vasseur"} />
        </div>
        <div className="text-center">
          <ul className="sm:text-24 text-16 font-helvetica text-black">
            <li className="uppercase font-fraunces font-semibold mb-2">
              notre gamme de produit
            </li>
            {categories.data.map((category, index) => {
              return (
                <li key={uniqueId("footer_category_link--")}>
                  <Link
                    to={`/nos-gammes-produits/${category.id}/${category.attributes.name}`}
                  >
                    {" "}
                    {category.attributes.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="text-center mt-8">
          <ul className="sm:text-24 text-16 font-helvetica text-black">
            <li className="uppercase font-fraunces font-semibold mb-2">
              une question ?
            </li>
            <li>Téléphone: 03 21 53 42 65</li>
            <li>Fax: 03 21 53 41 18</li>
            <li>
              SAS VASSEUR <br />
              Rue de la Perelle <br />
              62620 Ruitz
            </li>
          </ul>
        </div>

        <hr className="border border-golden-500 mx-24 mt-8 xl:hidden" />
      </div>
      <div className="flex xl:hidden flex-row justify-center space-x-2 mx-4">
        <div className="flex flex-row flex-wrap justify-center gap-4 mx-auto">
          <img className="h-24" src={UE} alt={""} />
          <img className="h-24" src={HDF} alt={""} />
          <img className="h-24" src={HDF2} alt={""} />
        </div>
      </div>

      <div className="mx-auto mt-4 xl:hidden">
        <p className="font-helvetica font-medium sm:text-16 text-10 text-center">
          CE PROJET EST CO-FINANCÉ PAR LE FONDS SOCIAL EUROPÉEN
        </p>
        <p className="font-helvetica sm:text-16 text-10 text-center">
          Financement dans le cadre de la réponse de l’Union à la pandémie de
          COVID-19
        </p>
      </div>

      <hr className="border border-golden-500 mx-24 mt-4 mb-12 xl:hidden" />

      <div className="text-16 text-gray-600 font-helvetica text-center pb-4">
        <p> site réalisé avec &nbsp;🖤&nbsp;par Mister E</p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

import GenericLayout from "../layouts/GenericLayout";
import { useUniverses } from "../_services/universe-services";

import flunch from "../../../assets/images/references/flunch.jpg";
import troisb from "../../../assets/images/references/troisb.png";
import vinci from "../../../assets/images/references/vinci.svg";
import hdf from "../../../assets/images/references/logo_HDF.svg";
import decat from "../../../assets/images/references/decat.png";
import api from "../../../assets/images/references/api.jpg";
import { Link } from "react-router-dom";

const ReferencePage = () => {
  const { universes, isLoading, isError } = useUniverses();
  if (isLoading) return <div>chargement</div>;
  if (isError) return <div>erreur dans le chargement des univers</div>;

  return (
    <GenericLayout title={"nos références"}>
      {universes.data.map(({ attributes }) => {
        return (
          <div className="">
            <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:py-8 lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start font-brandon">
                <div>
                  <div
                    className={
                      "aspect-w-1 aspect-h-1 rounded-lg bg-cover bg-no-repeat bg-center "
                    }
                    style={{
                      backgroundImage: `url(${attributes.cover_picture_url})`,
                    }}
                  />
                  <h2 className="text-3xl font-extrabold text-gray-900 sm:text-2xl">
                    {attributes.name}
                  </h2>
                  <p className="mt-3 max-w-3xl text-lg text-gray-500 font-brandon">
                    {attributes.description}
                  </p>
                  <div className="mt-8 sm:flex">
                    <div className="rounded-md shadow">
                      <Link
                        to={`/univers/${attributes.id}`}
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-skin-600 hover:bg-skin-700 font-brandon"
                      >
                        Voir nos réalisations {attributes.name}
                      </Link>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <Link
                        to="/contact"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-skin-700 bg-skin-100 hover:bg-skin-200"
                      >
                        Nous contacter
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2 sticky top-0">
                  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-12" src={api} alt="Workcation" />
                  </div>
                  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-12" src={flunch} alt="Mirage" />
                  </div>
                  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-12" src={troisb} alt="Tuple" />
                  </div>
                  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-12" src={hdf} alt="Laravel" />
                  </div>
                  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-12" src={vinci} alt="StaticKit" />
                  </div>
                  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                    <img className="max-h-12" src={decat} alt="Statamic" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </GenericLayout>
  );
};

export default ReferencePage;

import classNames from "classnames";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import React from "react";
import Breadcrumbs from "./breadcrumbs";

const HeroSection = ({ index, article, breadcrumbs }) => {
  return (
    <div
      className={classNames(
        {
          "sm:flex-row-reverse": index % 2 !== 0,
          "sm:flex-row": index % 2 === 0,
        },
        "flex justify-between flex-col-reverse justify-between"
      )}
    >
      <div className="xl:w-2/5 sm:w-1/3 flex-col container px-5 max-w-lg">
        {index === 0 && <Breadcrumbs breadCrumbs={breadcrumbs} />}
        <h1 className="font-freight font-skin sm:text-40 text-32 mb-4 sm:mt-5 mt-8">
          {article.title}
        </h1>

        <div
          className="sm:space-y-10 space-y-6 font-grotesque text-skin text-justify sm:text-20 text-16"
          dangerouslySetInnerHTML={{ __html: article.body }}
        />
        {article.link && (
          <Link
            className="button-translation xl:flex hidden font-grotesque text-2xl mt-10 py-4 px-6 rounded-md shadow items-center"
            to={article.link}
          >
            En savoir plus
          </Link>
        )}
      </div>

      <div className={"sm:w-3/5"}>
        <div
          className="imganim aspect-w-1 aspect-h-1 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${article.imageURL})`,
          }}
        />
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  index: PropTypes.number,
  article: PropTypes.any,
};
export default HeroSection;

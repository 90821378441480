import React from "react";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useRealisations } from "../../../_services/realisation-service";

const AdminRealisationsPage = () => {
  const { realisations, isError, isLoading } = useRealisations();

  if (isError) {
    return <div>Loading failed ...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>Actualités</h3>
        <Link
          to={"/admin/realisations/new"}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-700 border border-skin-500 hover:text-white bg-transparent hover:bg-skin-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
        >
          <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Ajouter une actualité
        </Link>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Nom
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    date de realisation
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading &&
                  realisations?.data.map(
                    ({ attributes, relationships }, personIdx) => (
                      <tr
                        key={`${attributes.reference}__${personIdx}`}
                        className={
                          personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {attributes.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {attributes.release_date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/admin/realisations/${attributes.id}`}
                            className="text-skin-600 hover:text-skin-900"
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRealisationsPage;

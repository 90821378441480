import Api, { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";

export class CategoryServices {
  static createCategory = (data, callback) => {
    Api.post(
      `${API_V1_BASE_URL}/categories`,
      { category: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      // .then(category => uploadFile(blobSignedID, category))
      .then(callback);
  };
  static updateCategory = (id, data, callback) => {
    Api.put(
      `${API_V1_BASE_URL}/categories/${id}`,
      { category: data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.data)
      .then(callback);
  };
  static deleteCategory = (id, data, callback) => {
    Api.delete(`${API_V1_BASE_URL}/categories/${id}`).then(callback);
  };
}

export const useCategories = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/categories`, getFetcher);

  return {
    categories: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useCategory = (category_id) => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/categories/${category_id}`,
    getFetcher
  );

  return {
    category: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCategoriesOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_categories_options`,
    getFetcher
  );
  return {
    categories: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const searchCategories = (query, setResults) => {
  Api.post(
    `${API_V1_BASE_URL}/searches/search_by_categories/`,
    {
      query: query,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  ).then((res) => setResults(res.data));
};

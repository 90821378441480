import React from "react";

import GenericLayout from "../layouts/GenericLayout";
import { useParams } from "react-router-dom";
import Loading from "../components/utils/Loading";
import Error from "../components/Error";
import { useInspiration } from "../_services/inspiration-service";
import Thumb from "../components/realisations/Thumb";
import Breadcrumbs from "../components/breadcrumbs";
import RealisationProductsSlider from "./RealisationProductsSlider";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";

const InspirationPage = () => {
  const { inspiration_id } = useParams();

  console.log(inspiration_id);

  const { inspiration, isError, isLoading } = useInspiration(inspiration_id);
  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;

  const { id, title, description, pictures_url } = inspiration.data.attributes;
  const products = getAllIncludedResourcesForAGivenType(inspiration, "product");

  const realisationBC = [
    { label: "ADM", link: "/" },
    { label: "Inspirations", link: "/inspirations" },
    { label: title, link: `/inspirations/${id}` },
  ];

  return (
    <GenericLayout>
      <h1 className="text-center uppercase sm:text-72 text-24 mt-8 sm:mb-20 mb-10 font-freight stroke-golden-500 tracking-wider">
        {name}
      </h1>

      <div className="xl:mx-48 md:mx-12 mx-4 sm:mb-28">
        <Breadcrumbs breadCrumbs={realisationBC} />

        <div className="flex xl:flex-row flex-col gap-x-6">
          <div className="flex-1 flex-col">
            <div className="flex sm:flex-col flex-col-reverse xl:mr-24 mb-6">
              <div className="flex-1">
                <div className="flex items-end font-freight sm:justify-start justify-center">
                  <h2 className="sm:text-40 text-32 sm:mr-4 mr-0 mt-6">
                    {title}
                  </h2>
                </div>
                <p className="font-grotesque sm:text-20 text-16 sm:my-8 my-4 text-justify">
                  {description}
                </p>
              </div>

              <div
                className="xl:flex-1 aspect-w-1 aspect-h-1 bg-cover bg-center rounded-lg"
                style={{ backgroundImage: `url(${pictures_url[0]})` }}
              />
            </div>

            <div className="flex flex-col items-stretch">
              <div className="xl:self-end w-full xl:w-80">
                <div
                  className="aspect-h-12 aspect-w-9 bg-cover bg-center rounded-lg"
                  style={{ backgroundImage: `url(${pictures_url[1]})` }}
                />
              </div>
            </div>
          </div>

          <div className="flex-1 xl:mt-0 mt-8">
            <div
              className="xl:flex-1 aspect-w-8 aspect-h-12 w-full bg-cover bg-center rounded-lg"
              style={{ backgroundImage: `url(${pictures_url[2]})` }}
            />
          </div>
        </div>

        <h2 className="sm:block hidden sm:mb-2 mb-6 sm:mt-24 sm:mt-8 mt-6 font-freight sm:text-40 text-2xl text-left">
          Liste des produits proposés par ADM
        </h2>
        <h2 className="sm:hidden block sm:mb-2 mb-6 sm:mt-24 sm:mt-8 mt-6 font-freight sm:text-40 text-2xl text-left">
          Liste des produits proposés
        </h2>

        <RealisationProductsSlider products={products} />

        {/*<div classNameName={"divide-y divide-black"}>*/}
        {/*  {inspiration.map((realisation, index) => {*/}
        {/*      return (*/}
        {/*        <Thumb*/}
        {/*          realisation_id={realisation.id}*/}
        {/*          isReverse={index % 2 !== 0}*/}
        {/*        />*/}
        {/*      );*/}
        {/*    }*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </GenericLayout>
  );
};

export default InspirationPage;

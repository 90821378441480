import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import React from "react";
import { isLarge, isRegular } from "../../pages/CategoryPage";

const PreviewCard = ({
  name,
  id,
  thumb_type = "regular",
  cover_picture_url,
  reference,
  readOnly = false,
}) => {
  console.log(cover_picture_url);

  const navigate = useNavigate();
  const handleNavigation = () => {
    return !readOnly ? navigate(`/produit/${id}`) : null;
  };

  return (
    <div
      onClick={handleNavigation}
      className={classNames(
        {
          "col-span-2": isRegular(thumb_type),
          "col-span-3": isLarge(thumb_type),
          "cursor-pointer": !readOnly,
        },
        "bg-skin-100 rounded-sm flex flex-col shadow"
      )}
    >
      <div
        className={classNames(
          {
            "aspect-h-1 aspect-w-1 bg-center bg-cover": isRegular(thumb_type),
            "aspect-w-16 aspect-h-9 bg-center bg-cover": isLarge(thumb_type),
          },
          "overflow-hidden rounded-t-sm h-full"
        )}
        style={{
          backgroundImage: `url(${cover_picture_url})`,
        }}
      />
      <div className={"p-4"}>
        <h2 className="font-fraunces sm:text-20 text-16 text-center hidden sm:block">
          {reference}
        </h2>
        <p className="font-fraunces sm:text-justify sm:text-center sm:text-base mt-2 flex ">
          {name}
        </p>
      </div>
    </div>
  );
};

export default PreviewCard;

import slugify from "react-slugify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CategoryServices } from "../../../_services/category-services";
import { DirectUpload } from "@rails/activestorage";
import Api from "../../../_helpers/api";
import useSWR from "swr";
import classNames from "classnames";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import PreviewCard from "../PreviewCard";

const AdminCategoriesAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [coverPictureBlobSignedID, setCoverPictureBlobSignedID] = useState({});
  const [category, setCategory] = useState({});
  const [coverPicture, setCoverPicture] = useState({});
  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");

  const navigate = useNavigate();

  let { category_id } = useParams();
  let isEditMode = !!category_id;

  const [isLoaded, setIsLoaded] = useState(true);

  const goBack = () => {
    navigate("/admin/categories");
  };

  const onSubmit = (data) => {
    let submitted_data = {
      ...data,
      cover_picture: coverPictureBlob,
    };

    return isEditMode
      ? CategoryServices.updateCategory(category_id, submitted_data, goBack)
      : CategoryServices.createCategory(submitted_data, goBack);
  };

  const handleDelete = () => {
    CategoryServices.deleteCategory(category_id, goBack);
  };

  const fetcher = (url) =>
    Api.get(url).then((response) => response.data.data.attributes);
  const { data: categoryData, error } = useSWR(
    () => (category_id ? `/api/v1/categories/${category_id}` : null),
    fetcher
  );

  useEffect(() => {
    if (!!categoryData) {
      const fields = ["name", "description", "slug"];
      fields.forEach((field) => setValue(field, categoryData[field]));
      setCoverPicture(categoryData.cover_picture_url);
      setPreviewURL(categoryData.cover_picture_url);
      setCategory(categoryData);
    }
  }, [categoryData]);

  if (!categoryData && category_id) {
    return <div>Chargement de la gamme de produits...</div>;
  }

  if (error) {
    console.error(error);
    return <div> Erreur dans le chargement ...</div>;
  }

  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className={"flex flex-row justify-between items-center"}>
          <h3 className={"text-3xl py-5"}>
            {isEditMode ? "Edition d'une" : "Création d'une nouvelle "} gamme de
            produits
          </h3>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Informations générales
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Ces informations seront visibles sur la page de description du
                  produit.
                </p>
              </div>

              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      {...register("name")}
                      className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      autoComplete="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Description
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      {...register("description", { required: false })}
                      id="description"
                      name="description"
                      rows={5}
                      className="max-w-lg shadow-sm block w-full focus:ring-skin-500 focus:border-skin-500 sm:text-sm border border-gray-300 rounded-md"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Description succinte du produit.
                    </p>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Photo de couverture
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <CoverPictureManager
                        coverPicture={coverPicture}
                        setCoverPicture={setCoverPicture}
                        coverPictureBlob={coverPictureBlob}
                        setCoverPictureBlob={setCoverPictureBlob}
                        setPreviewURL={setPreviewURL}
                        previewURL={previewURL}
                        setIsLoaded={setIsLoaded}
                        thumb_type={watch("thumb_type")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 flex flex-row justify-between">
            <div>
              <span
                onClick={handleDelete}
                className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
              >
                Supprimer
              </span>
            </div>
            <div className="flex justify-end">
              <button
                onClick={goBack}
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
              >
                Annuler
              </button>
              <button
                disabled={!isLoaded}
                type="submit"
                className={classNames(
                  !isLoaded
                    ? "bg-skin-700"
                    : "bg-skin-800 hover:bg-skin-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500",
                  "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white "
                )}
              >
                {isLoaded
                  ? isEditMode
                    ? "Sauvegarder les modifications"
                    : "Créer une nouvelle gamme de produits"
                  : "Chargememt de la photo en cours"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className={"flex flex-col w-1/2"}>
        <div className={"sticky top-0 p-4 m-4 bg-beige-400 rounded-lg"}>
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la vignette de présentation
          </h3>
          <div className={"grid grid-cols-2"}>
            <PreviewCard
              reference={watch("name")}
              cover_picture_url={previewURL}
              thumb_type={watch("thumb_type")}
              name={watch("description")}
              readOnly={true}
              id={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCategoriesAddEditPage;

import React from "react";
import { Link } from "react-router-dom";

const InspirationItem = ({ inspiration }) => {
  const { id, title, cover_picture_url, description } = inspiration.attributes;

  return (
    <Link to={`/inspirations/${id}`} key={id} className="group relative">
      <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80">
        <img
          src={cover_picture_url}
          alt={name}
          className="w-full h-full object-center object-cover lg:w-full lg:h-full"
        />
      </div>
      <div className="mt-3 flex justify-between">
        <div>
          <h2 className="font-freight xl:text-40 sm:text-3xl text-20 font-bold first-letter:capitalize">
            {title}
          </h2>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default InspirationItem;

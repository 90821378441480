import React from "react";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useContacts } from "../../../_services/contact-services";
import classNames from "classnames";

const AdminContactPage = () => {
  const { contacts, isError, isLoading } = useContacts();

  if (isLoading) {
    return <div>Chargement des Conditionnement...</div>;
  }
  if (isError) {
    console.error(isError);
    throw isError;
    return <div>Une erreur est survenue ...</div>;
  }
  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>Contacts</h3>
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      informations
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      consentement
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Connu par
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {contacts.data.map(({ attributes }) => {
                    const {
                      id,
                      firstname,
                      lastname,
                      email,
                      phone_number,
                      company,
                      message,
                      how_did_you_know,
                      consent,
                    } = attributes;

                    return (
                      <tr key={id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{`${firstname} ${lastname}`}</div>
                              <div className="text-sm text-gray-500">
                                {company}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {phone_number}
                          </div>
                          <div className="text-sm text-gray-500">{email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={classNames(
                              {
                                "text-red-800 bg-red-100 ": consent,
                                "bg-green-100 text-green-800": !consent,
                              },
                              "px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
                            )}
                          >
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {how_did_you_know}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/admin/contacts/${id}`}
                            className="text-skin-600 hover:text-skin-900 hover:underline"
                          >
                            voir la fiche
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminContactPage;

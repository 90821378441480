import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProduct } from "../_services/product-services";
import Loading from "../components/utils/Loading";
import Error from "../components/Error";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";
import SimilarProductsSlider from "../components/SimilarProductsSlider";
import classNames from "classnames";
import { isLarge, isRegular } from "./CategoryPage";
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import ReactPlayer from "react-player";

const ProductPage = () => {
  const navigate = useNavigate();
  let { product_id } = useParams();

  const { product, isLoading, isError } = useProduct(product_id);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error error={isError} />;
  }

  console.log(product);

  const {
    name,
    reference,
    structure,
    properties,
    width,
    height,
    depth,
    more_details,
    cover_picture_url,
    pictures_data,
    thumb_type,
    doc_pdf_data,
    video_url,
    file_sketchup_data,
  } = product.data.attributes;
  const materials = getAllIncludedResourcesForAGivenType(product, "material");
  const categories = getAllIncludedResourcesForAGivenType(product, "category");

  return (
    <GenericLayout title={`${name}`}>
      <div className="xl:mx-48 sm:mx-12 mx-4">
        <div className="sm:hidden block sm:m-0 mb-6">
          <img src={cover_picture_url} alt={name} />
        </div>

        <nav className="flex mb-8 sm:block hidden" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center">
            <li>
              <div className="flex items-center">
                <Link
                  to="/produits"
                  className=" text-24 font-medium text-skin hover:text-black font-freight"
                >
                  ADM
                </Link>
              </div>
            </li>

            <li>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                  href="#"
                  className="text-24 uppercase font-medium text-skin hover:text-black font-freight"
                  aria-current="page"
                >
                  Fiche produit
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <a
                  href="#"
                  className="text-24 uppercase font-medium text-skin hover:text-black font-freight"
                  aria-current="page"
                >
                  {name}
                </a>
              </div>
            </li>
          </ol>
        </nav>

        <div className="flex sm:flex-row flex-col justify-between items-start">
          <div className="sm:w-vw-5/12">
            <div className="flex sm:flex-col sm:space-y-8 sm:space-x-0 space-x-4">
              {video_url && (
                <div className={"w-full aspect-w-16 aspect-h-9"}>
                  <ReactPlayer url={video_url} width={"100%"} height={"100%"} />
                </div>
              )}
              <div className="sm:w-vw-5/12">
                <div
                  className={classNames(
                    {
                      "aspect-h-9 aspect-w-8 bg-center bg-contain":
                        isRegular(thumb_type),
                      "aspect-w-16 aspect-h-9 bg-center bg-contain":
                        isLarge(thumb_type),
                    },
                    "overflow-hidden rounded-t-sm h-full"
                  )}
                  style={{ backgroundImage: `url(${cover_picture_url})` }}
                />
              </div>
              {pictures_data?.map(({ url }, index) => {
                return (
                  <div
                    key={index}
                    className="aspect-w-1 aspect-h-1 bg-center bg-cover rounded-lg"
                    style={{ backgroundImage: `url(${url})` }}
                  />
                );
              })}
            </div>
          </div>

          <div className="sm:w-vw-1/3 sm:sticky sm:top-10 sm:mt-0 mt-8 flex">
            <div className="sm:space-y-10 space-y-2">
              <div>
                <p className="font-freight font-bold lg:text-40 text-32 text-skin capitalize">
                  {name}
                </p>
              </div>
              <div>
                <p className="font-freight font-bold lg:text-32 text-24 text-skin">
                  Référence:
                </p>
                <p className="font-grotesque text-skin sm:text-20 text-16">
                  {reference}
                </p>
              </div>
              {structure && (
                <div>
                  <p className="font-freight font-bold lg:text-32 text-24 text-skin">
                    Structure:{" "}
                  </p>
                  <p className="font-grotesque text-skin sm:text-20 text-16">
                    {structure}
                  </p>
                </div>
              )}
              {properties && (
                <div>
                  <p className="font-freight font-bold lg:text-32 text-24 text-skin">
                    Caractéristiques:
                  </p>
                  <p className={"font-grotesque text-skin sm:text-20 text-16"}>
                    {properties}
                  </p>
                </div>
              )}
              <div>
                <p className="font-freight font-bold lg:text-32 text-24 text-skin">
                  Dimensions
                </p>
                <p className="font-grotesque text-skin sm:text-20 text-16">
                  {width} / {height} / {depth} mm
                </p>
              </div>
              {categories && (
                <div>
                  <p className="font-freight font-bold lg:text-32 text-24 text-skin">
                    Categories
                  </p>
                  <p className="font-grotesque text-skin sm:text-20 text-16 space-x-2">
                    {categories.map(({ attributes }) => (
                      <Link
                        key={attributes.id}
                        to={`/category/${attributes.id}`}
                      >
                        <span className={"px-2 text-skin-400"}>
                          #{attributes.name}
                        </span>
                      </Link>
                    ))}
                  </p>
                </div>
              )}
              {(doc_pdf_data || file_sketchup_data) && (
                <div>
                  <p className="font-freight font-bold lg:text-32 text-24 text-skin ">
                    Documents:
                  </p>
                  <div className={"flex flex-col space-y-4"}>
                    {doc_pdf_data && (
                      <div
                        className={
                          "flex font-grotesque text-skin sm:text-lg text-16 w-full bg-beige-500 rounded-lg px-2 "
                        }
                      >
                        <a
                          href={doc_pdf_data.url}
                          className={" hover:underline inline-flex  "}
                        >
                          <DocumentDownloadIcon className={"w-7 h-7"} />{" "}
                          Document PDF
                        </a>
                      </div>
                    )}
                    {file_sketchup_data && (
                      <div
                        className={
                          "flex font-grotesque text-skin sm:text-lg text-16 w-full bg-beige-500 rounded-lg px-2"
                        }
                      >
                        <a
                          href={file_sketchup_data.url}
                          className={
                            " hover:underline inline-flex justify-center"
                          }
                        >
                          <DocumentDownloadIcon className={"w-7 h-7"} />{" "}
                          Document Sketchup
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {materials.length !== 0 && (
                <div>
                  <p className="font-freight font-bold lg:text-32 text-24 text-skin">
                    Matières
                  </p>
                  <p className="font-grotesque text-skin sm:text-20 text-16">
                    {materials.map((material, index) => {
                      return (
                        <span
                          key={`material__${index}`}
                          className={"capitalize"}
                        >
                          {material?.attributes.name}
                        </span>
                      );
                    })}
                  </p>
                </div>
              )}
              {more_details && (
                <div>
                  <p className="font-freight font-bold lg:text-40 text-24 text-skin">
                    Informations complémentaires
                  </p>
                  <p className="font-grotesque text-skin sm:text-20 text-16">
                    {" "}
                    {more_details}{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <hr className="sm:hidden flex border-black border-1 mx-16 my-12" />

        <h2 className="hidden sm:block sm:mt-12 sm:mb-0 mb-4 font-freight sm:text-40 text-32">
          Suggestion de produits similaires
        </h2>
        <p className="text-skin font-grotesque text-20 sm:block hidden mb-12">
          Nous vous proposons d'autre produits similaire à {name}, si vous avez
          une question n'hésitez pas à nous contacter
        </p>

        <SimilarProductsSlider categories={categories} />
      </div>
    </GenericLayout>
  );
};

export default ProductPage;

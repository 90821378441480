import React from "react";
import ReactQuill from "react-quill";

export default function RichTextEditor({ content, setContent }) {
  return (
    <ReactQuill
      className={"relative h-full"}
      theme="snow"
      value={content}
      onChange={setContent}
    />
  );
}

import React from "react";
import GenericLayout from "../layouts/GenericLayout";

const MentionsLegales = () => {
  return (
    <GenericLayout title={"Mentions légales"}>
      <div className="relative ">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1567&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-skin-600 font-semibold tracking-wide uppercase">
                Mentions légales
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-skin-900 sm:text-4xl">
                Présentation du site
              </h3>
              <p className="mt-8 text-lg text-gray-500">
                En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004
                pour la confiance dans l’économie numérique, il est précisé aux
                utilisateurs du site www.charcuterie-vasseur.com l’identité des
                différents intervenants dans le cadre de sa réalisation et de
                son suivi : Propriétaire : Charcuterie Vasseur – 313 670 812
                00038 – Parc Entreprise Brunehaut, 62470 Calonne-Ricouart
                Créateur : PomA.PomV Le responsable publication est une personne
                physique ou une personne morale. Hébergeur : OVH – 2 Rue
                Kellermann, 59100 Roubaix Crédits : Les mentions légales ont été
                générées et offertes par Subdelirium Mentions légales
                <ul>
                  <li>ADM, sièges et tables pour la restauration.</li>
                  <li>Rue Norbert Ségard 59223 RONCQ</li>
                  <li>tel: 03 20 03 88 88</li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </p>
              <div className="mt-5 prose prose-indigo text-gray-500">
                <h2 className="leading-6 text-skin-600 font-semibold tracking-wide uppercase">
                  Clauses de non-responsabilité quant aux contenus
                </h2>
                <p>
                  Les informations et éléments de ce site sont fournis à titre
                  indicatif uniquement. Tout ou partie des informations et
                  éléments diffusés sur le site, sont fournis en l'état, sans
                  aucune garantie d'aucune sorte, expresse ou implicite, et
                  notamment sans que ceci soit limitatif, de disponibilité,
                  d'exactitude ou de caractère complet. ADM - Mobilier ne
                  saurait être tenu pour responsable d'un préjudice direct ou
                  indirect résultant de l'utilisation d'informations contenues
                  sur ce site.
                </p>

                <p>
                  Le site peut notamment présenter des erreurs techniques et
                  typographiques ou autres inexactitudes, ce que vous
                  reconnaissez et acceptez en utilisant le site. ADM - Mobilier
                  ne saurait être tenu responsable des erreurs ou omissions
                  présentées par et/ou contenues dans le site ou par tout
                  document référencé. Les informations contenues dans le site
                  sont non-contractuelles et sujettes à modification à tout
                  moment. Les informations présentées sur le site font
                  régulièrement l'objet de mises à jour.
                </p>

                <p>
                  {" "}
                  Vasseur ne garantit en aucune manière que les fonctions
                  contenues dans le site ou l'accès au site seront assurés sans
                  interruption et sans erreur, ni que le site ou le serveur qui
                  les met à disposition sont exempts de tout virus et autres
                  composants dangereux. En aucune circonstance, Vasseur ne sera
                  responsable des préjudices fortuits, directs ou indirects,
                  résultant de l'utilisation de tout ou partie des éléments du
                  site.
                </p>

                <div>
                  Tout lien brisé, toute omission ou erreur doivent être
                  signalés par notre formulaire de contact. La responsabilité de
                  Vasseur ne saurait être directement ou indirectement retenue,
                  à quelque titre et pour quelque cause que ce soit :
                </div>
                <div className={"p-3"}>
                  <ul className={"list-disc"}>
                    <li>
                      en cas d'interruption de service du site motivée par des
                      opérations de maintenance ou par les comportements des
                      utilisateurs du site,
                    </li>
                    <li>
                      en cas d'inaccessibilité et/ou en cas d'incapacité à
                      utiliser le site par tout utilisateur du site,
                    </li>
                    <li>
                      en cas de dommages d'équipements de toute forme que ce
                      soit, de pertes de données, lors d'une connexion au site.
                    </li>
                  </ul>
                </div>
              </div>
              <p className="prose">
                Le site www.charcuterie-vasseur.com contient un certain nombre
                de liens hypertextes vers d’autres sites, mis en place avec
                l’autorisation de Charcuterie Vasseur. Cependant, Charcuterie
                Vasseur n’a pas la possibilité de vérifier le contenu des sites
                ainsi visités, et n’assumera en conséquence aucune
                responsabilité de ce fait. La navigation sur le site
                www.charcuterie-vasseur.com est susceptible de provoquer
                l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
                Un cookie est un fichier de petite taille, qui ne permet pas
                l’identification de l’utilisateur, mais qui enregistre des
                informations relatives à la navigation d’un ordinateur sur un
                site. Les données ainsi obtenues visent à faciliter la
                navigation ultérieure sur le site, et ont également vocation à
                permettre diverses mesures de fréquentation. Le refus
                d’installation d’un cookie peut entraîner l’impossibilité
                d’accéder à certains services. L’utilisateur peut toutefois
                configurer son ordinateur de la manière suivante, pour refuser
                l’installation des cookies : Sous Internet Explorer : onglet
                outil (pictogramme en forme de rouage en haut a droite) /
                options internet. Cliquez sur Confidentialité et choisissez
                Bloquer tous les cookies. Validez sur Ok. Sous Firefox : en haut
                de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis
                aller dans l’onglet Options. Cliquer sur l’onglet Vie privée.
                Paramétrez les Règles de conservation sur : utiliser les
                paramètres personnalisés pour l’historique. Enfin décochez-la
                pour désactiver les cookies. Sous Safari : Cliquez en haut à
                droite du navigateur sur le pictogramme de menu (symbolisé par
                un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les
                paramètres avancés. Dans la section « Confidentialité », cliquez
                sur Paramètres de contenu. Dans la section « Cookies », vous
                pouvez bloquer les cookies. Sous Chrome : Cliquez en haut à
                droite du navigateur sur le pictogramme de menu (symbolisé par
                trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur
                Afficher les paramètres avancés. Dans la section «
                Confidentialité », cliquez sur préférences. Dans l’onglet «
                Confidentialité », vous pouvez bloquer les cookies.
              </p>
              <div className="prose">
                <h3 className={"font-bold"}>
                  Droit applicable et attribution de juridiction.
                </h3>
                Tout litige en relation avec l’utilisation du site
                www.charcuterie-vasseur.com est soumis au droit français. Il est
                fait attribution exclusive de juridiction aux tribunaux
                compétents de Paris.
              </div>
              <div className="prose">
                <h3 className={"font-bold"}>
                  Les principales lois concernées.
                </h3>
                Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n°
                2004-801 du 6 août 2004 relative à l’informatique, aux fichiers
                et aux libertés. Loi n° 2004-575 du 21 juin 2004 pour la
                confiance dans l’économie numérique.
              </div>
              <div className="prose">
                <h3 className={"font-bold"}>Lexique</h3>
                Utilisateur : Internaute se connectant, utilisant le site
                susnommé. Informations personnelles : « les informations qui
                permettent, sous quelque forme que ce soit, directement ou non,
                l’identification des personnes physiques auxquelles elles
                s’appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
              </div>
            </div>
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default MentionsLegales;

import React from "react";
import { useRealisation } from "../../_services/realisation-service";
import Error from "../Error";
import Loading from "../utils/Loading";
import { Link } from "react-router-dom";
import classNames from "classnames";

const RealisationThumb = ({ realisation_id, isReverse = false }) => {
  const { realisation, isError, isLoading } = useRealisation(realisation_id);

  if (isError) return <Error error={isError} />;
  if (isLoading) return <Loading />;

  const { id, name, description, cover_picture_url, place } =
    realisation.data.attributes;

  return (
    <div
      className={classNames(
        {
          "sm:flex-row-reverse": isReverse,
          "sm:flex-row": !isReverse,
        },
        "flex justify-between flex-col-reverse justify-between py-4"
      )}
    >
      <div className="xl:w-2/5 sm:w-1/3 flex-col container px-5 max-w-lg">
        <div className="flex items-end font-freight sm:justify-start justify-center mb-4">
          <h2 className="sm:text-40 text-32 sm:mr-4 mr-0 mt-6 capitalize">
            "{name}"
          </h2>
          <p className="items-end sm:text-20 text-16 sm:ml-0 ml-4">-{place}-</p>
        </div>
        <div
          className="sm:space-y-10 space-y-6 font-grotesque text-skin text-justify sm:text-20 text-16 max-w-lg "
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Link
          className="button-translation xl:flex hidden font-grotesque text-2xl mt-10 py-4 px-6 rounded-md shadow items-center"
          to={`/realisations/${id}`}
        >
          En savoir plus
        </Link>
      </div>
      <div className={"sm:w-3/5"}>
        <div
          className="imganim aspect-w-1 aspect-h-1 bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${cover_picture_url})`,
          }}
        />
      </div>
    </div>
  );
};

export default RealisationThumb;

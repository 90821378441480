import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import AdminLayout from "../layouts/AdminLayout";
import Api from "../_helpers/api";

export function PrivateRoute({ children }) {
  let { sessionAuthHeader } = useAuth();
  let location = useLocation();
  if (!sessionAuthHeader?.email) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }

  console.log(sessionAuthHeader);
  useEffect(() => {
    if (sessionAuthHeader) {
      Api.defaults.headers.common["X-ADMIN-EMAIL"] = sessionAuthHeader.email;
      Api.defaults.headers.common["X-ADMIN-TOKEN"] = sessionAuthHeader.token;
    }
  }, [sessionAuthHeader]);

  return <AdminLayout>{children}</AdminLayout>;
}

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../../../_helpers/api";
import { Tab } from "@headlessui/react";
import { DirectUpload } from "@rails/activestorage";
import classNames from "classnames";
import { getAllIncludedResourcesForAGivenType } from "../../../_helpers/jsonapi-deserializer";
import {
  createRealisation,
  deleteRealisation,
  updateRealisation,
  useRealisation,
} from "../../../_services/realisation-service";
import SelectProducts from "../../../components/forms/SelectProducts";
import RichTextEditor from "../../../components/utils/RichTextEditor";
import {
  EditorState,
  convertFromHTML,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import CoverPictureManager from "../../../components/utils/CoverPictureManager";
import PreviewCard from "../PreviewCard";

const AdminRealisationAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [coverPicture, setCoverPicture] = useState({});
  const [dataCoverPicture, setDataCoverPicture] = useState("");
  const [dataPictures, setDataPictures] = useState([]);
  const [picturesProduct, setPicturesProduct] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [realisationProducts, setRealisationProducts] = useState([]);
  const [coverPictureBlobSignedID, setCoverPictureBlobSignedID] = useState({});

  const [coverPictureBlob, setCoverPictureBlob] = useState({});
  const [previewURL, setPreviewURL] = useState("");

  const [description, setDescription] = useState("");

  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();

  let { realisation_id } = useParams();
  let isEditMode = !!realisation_id;

  const goBack = () => {
    navigate("/admin/realisations");
  };
  const onSubmit = (data) => {
    let submitted_data = {
      realisation: {
        ...data,
        description: description,
        product_ids: realisationProducts.map((product) => {
          return product.id;
        }),
        cover_picture: coverPictureBlob,
        pictures: picturesProduct,
      },
    };

    return isEditMode
      ? updateRealisation(realisation_id, submitted_data, goBack)
      : createRealisation(submitted_data, goBack);
  };
  const handleDelete = () => {
    deleteRealisation(realisation_id);
  };
  const uploadFile = (input, file) => {
    setIsLoaded(false);
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        input.name === "cover_picture"
          ? setCoverPicture(blob.signed_id)
          : setPicturesProduct((picturesProduct) =>
              [blob.signed_id].concat(picturesProduct)
            );
        setIsLoaded(true);
      }
    });
  };
  const handleFileUpload = (e) => {
    console.log(e.target.name);

    Array.from(e.target.files).forEach((file, index) => {
      e.target.name !== "pictures"
        ? setDataCoverPicture(URL.createObjectURL(file))
        : setDataPictures((dataPictures) =>
            [URL.createObjectURL(file)].concat(dataPictures)
          );

      uploadFile(e.target, file, index);
    });
    e.target.files.value = null;
  };

  const { realisation, isLoading, isError } = useRealisation(realisation_id);

  const fields = ["name", "place", "description", "release_date", "catch_line"];

  useEffect(() => {
    if (!!realisation) {
      fields.forEach((field) =>
        setValue(field, realisation.data.attributes[field])
      );
      setRealisationProducts(
        getAllIncludedResourcesForAGivenType(realisation, "product")
      );
      setCoverPicture(realisation.data.attributes.cover_picture_url);
      setPreviewURL(realisation.data.attributes.cover_picture_url);
      setDescription(realisation.data.attributes.description);
    }
  }, [realisation]);

  if (isLoading && !!realisation_id) {
    return <div>Product loading...</div>;
  }

  if (isError) {
    return <div> loading failed ...</div>;
  }
  return (
    <div className={"flex flex-row gap-8 relative"}>
      <div className="flex flex-col w-1/2">
        <div className="flex flex-col">
          <div className={"flex flex-row justify-between items-center"}>
            <h3 className={"text-3xl py-5"}>
              {isEditMode ? "Edition" : "Création"} actualité
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Informations générales
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Ces informations seront visibles sur la page de description
                    du produit.
                  </p>
                </div>

                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("name")}
                        className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        autoComplete="name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Phrase d'accroche
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        {...register("catch_line")}
                        className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        autoComplete="name"
                        rows={5}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Description
                    </label>
                    <div className={"relative col-span-2"}>
                      <RichTextEditor
                        content={description}
                        setContent={setDescription}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Date de le Réalisation
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <input
                        {...register("release_date")}
                        className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        autoComplete="name"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Photos
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Utilisez des photos présentant uniquement la réalisation en
                    question.
                  </p>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Photo de couverture
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <CoverPictureManager
                        coverPicture={coverPicture}
                        setCoverPicture={setCoverPicture}
                        coverPictureBlob={coverPictureBlob}
                        setCoverPictureBlob={setCoverPictureBlob}
                        setPreviewURL={setPreviewURL}
                        previewURL={previewURL}
                        setIsLoaded={setIsLoaded}
                        thumb_type={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
              <div className="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Produits composant la réalisation :
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <div className="mt-4 space-y-4">
                          <div className="space-y-6">
                            <SelectProducts
                              selectedOptions={realisationProducts}
                              setSelectedOptions={setRealisationProducts}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-5 flex flex-row justify-between">
              <div>
                <span
                  onClick={handleDelete}
                  className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
                >
                  Supprimer
                </span>
              </div>
              <div className="flex justify-end">
                <Link
                  to={"/admin/"}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
                >
                  Annuler
                </Link>
                <button
                  disabled={!isLoaded}
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-skin-600 hover:bg-skin-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
                >
                  {isLoaded
                    ? isEditMode
                      ? "Sauvegarder les modifications"
                      : "Créer un nouveau produit"
                    : "Chargement de la photo en cours"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={"flex flex-col w-full w-1/2"}>
        <div
          className={"sticky top-0 relative p-4 m-4 bg-beige-400 rounded-lg"}
        >
          <h3 className="text-lg leading-6 font-medium text-gray-900 py-4">
            Apercu de la vignette de présentation
          </h3>
          <div className={"grid grid-cols-2"}>
            <PreviewCard
              // reference={watch("name")}
              cover_picture_url={previewURL}
              thumb_type={"regular"}
              // name={watch("description")}
              readOnly={true}
              id={""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminRealisationAddEditPage;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import SearchInput from "./SearchInput";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "La maison", href: "/la-maison-vasseur", current: true },
  { name: "Notre charcuterie", href: "/nos-gammes-produits", current: false },
  { name: "actualites", href: "/nos-actualites", current: false },
  { name: "contact", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { pathname } = useLocation();

  return (
    <Disclosure
      as="header"
      className="bg-white fixed w-full z-50"
      id={"navbar"}
    >
      {({ open }) => (
        <>
          <div className={"flex flex-col justify-center"}>
            <div className={"shadow"}>
              <div className="max-w-screen-xl mx-auto px-2 sm:px-4 lg:px-8 ">
                <div className="relative lg:h-48 flex justify-between align-bottom lg:p-3">
                  <div className="relative z-10 px-2 flex lg:px-0">
                    <Link
                      to="/"
                      className="flex-shrink-0 flex items-center p-2"
                    >
                      <img
                        className="block h-auto w-20 lg:w-64"
                        src={logo}
                        alt="Workflow"
                      />
                    </Link>
                  </div>
                  <div className="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0">
                    <div className="flex flex-col h-full flex-grow justify-between justify-center py-4">
                      <p className="font-fraunces font-semibold text-16 hidden sm:block ">
                        La signature du savoir faire depuis 1960
                      </p>
                      <div className="w-full sm:max-w-2xl">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative flex flex-row">
                          <SearchInput />
                          <div className="pointer-events-none absolute inset-y-0 right-O pl-3 flex flex-col h-full justify-center items-center">
                            <SearchIcon
                              className="h-6 w-6 text-golden-500  "
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative z-10 flex items-center lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-golden-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-start py-4">
                    <nav className="flex flex-row space-x-2 text-golden-500 text-16 font-helvetica">
                      <p>
                        <Link to={"/contact"}>Nous contacter</Link>
                      </p>
                      <span>|</span>
                      <p>
                        <a href="tel:0321534265">03 21 53 42 65</a>
                      </p>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <nav
              className="hidden lg:py-2 lg:flex lg:space-x-12 lg:max-w-3xl lg:mx-auto"
              aria-label="Global"
            >
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.href === pathname
                      ? "text-golden-500 underline"
                      : "text-golden-900 hover:bg-skin-100 hover:text-golden-800",
                    "rounded-md py-2 inline-flex items-center text-lg uppercase font-medium font-fraunces whitespace-nowrap first-letter:uppercase"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
          <Disclosure.Panel
            as="nav"
            className={"lg:hidden"}
            aria-label="Global"
          >
            <div className="pt-2 pb-3 px-2 space-y-1 b border-2 border-golden-600 border-solid">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-900 hover:bg-skin-700 bg-opacity-75 hover:text-gray-900 ",
                    "block rounded-md py-2 px-3 text-base font-medium font-fraunces first-letter:uppercase"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

// export default NavBar;

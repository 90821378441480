import slugify from "react-slugify";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CategoryServices } from "../../../_services/category-services";
import { DirectUpload } from "@rails/activestorage";
import Api from "../../../_helpers/api";
import useSWR from "swr";
import classNames from "classnames";
import {
  createInspiration,
  deleteInspiration,
  updateInspiration,
  useInspiration,
} from "../../../_services/inspiration-service";
import SelectProducts from "../../../components/forms/SelectProducts";
import _ from "lodash";
import { Tab } from "@headlessui/react";

const AdminInspirationAddEditPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [coverPicture, setCoverPicture] = useState({});
  const [dataCoverPicture, setDataCoverPicture] = useState("");
  const [dataPictures, setDataPictures] = useState([]);
  const [pictures, setPictures] = useState([]);

  const navigate = useNavigate();

  let { inspiration_id } = useParams();
  let isEditMode = !!inspiration_id;

  const [isLoaded, setIsLoaded] = useState(true);

  const goBack = () => {
    navigate("/admin/inspirations");
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const uploadFile = (input, file) => {
    setIsLoaded(false);
    const upload = new DirectUpload(
      file,
      `${process.env.BASE_URL}/rails/active_storage/direct_uploads`
    );

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        input.name === "cover_picture"
          ? setCoverPicture(blob.signed_id)
          : setPictures((pictures) => [blob.signed_id].concat(pictures));
        setIsLoaded(true);
      }
    });
  };

  const handleFileUpload = (e) => {
    Array.from(e.target.files).forEach((file, index) => {
      const dataURL = URL.createObjectURL(file);
      console.log(e.target.name);
      e.target.name === "cover_picture"
        ? setDataCoverPicture(dataURL)
        : setDataPictures((dataPictures) => [dataURL].concat(dataPictures));

      uploadFile(e.target, file, index);
    });

    e.target.files.value = null;
  };

  const onSubmit = (data) => {
    let submitted_data = {
      ...data,
      product_ids: selectedProducts.map((product) => {
        return product.id;
      }),
      cover_picture: coverPicture,
      pictures: pictures,
    };

    return isEditMode
      ? updateInspiration(inspiration_id, submitted_data, goBack)
      : createInspiration(submitted_data, goBack);
  };

  const handleDelete = () => {
    deleteInspiration(inspiration_id, goBack);
  };

  const { inspiration, isError, isLoading } = useInspiration(inspiration_id);

  useEffect(() => {
    if (!!inspiration && inspiration_id) {
      const fields = [
        "title",
        "description",
        "slug",
        "reference",
        "release_date",
      ];
      fields.forEach((field) =>
        setValue(field, inspiration.data.attributes[field])
      );

      setSelectedProducts(
        _.filter(inspiration.included, (include) => {
          return include.type === "product";
        })
      );
    }
  }, [inspiration]);

  if (isLoading && inspiration_id) {
    return <div>Chargement de la categorie...</div>;
  }

  if (isError) {
    console.error(isError);
    return <div> Erreur dans le chargement ...</div>;
  }

  return (
    <div className="flex flex-col">
      <div className={"flex flex-row justify-between items-center"}>
        <h3 className={"text-3xl py-5"}>
          {isEditMode ? "Edition d'une" : "Création d'une nouvelle "}{" "}
          inspiration
        </h3>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Informations générales
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Ces informations seront visibles sur la page de description du
                produit.
              </p>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Titre
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("title")}
                    className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    autoComplete="title"
                    type="text"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reference
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("reference")}
                    className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    autoComplete="reference"
                    type="text"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Date de réalisation
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    {...register("release_date")}
                    className="max-w-lg block w-full shadow-sm focus:ring-skin-200 focus:border-skin-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    autoComplete="reference"
                    type="date"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  URL
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                      adm-mobilier.com/inspirations/
                    </span>
                    <input
                      {...register("slug", { required: false, disabled: true })}
                      type="text"
                      id="slug"
                      value={slugify(watch("title"))}
                      className="flex-1 block w-full focus:ring-skin-500 focus:border-skin-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 bg-gray-50 "
                    />
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Description
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    {...register("description", { required: false })}
                    id="description"
                    name="description"
                    rows={5}
                    className="max-w-lg shadow-sm block w-full focus:ring-skin-500 focus:border-skin-500 sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={""}
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Description succinte du produit.
                  </p>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Photo de couverture
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    {inspiration ? (
                      <>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="cover_picture--upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-skin-600 hover:text-skin-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-skin-500"
                          >
                            <input
                              id="cover_picture--upload"
                              name={"cover_picture"}
                              multiple={false}
                              type={"file"}
                              className={"sr-only"}
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={handleFileUpload}
                            />
                            {!dataCoverPicture ? (
                              <img
                                id={"cover-picture-preview"}
                                src={
                                  inspiration.data.attributes.cover_picture_url
                                }
                                alt={"Cover picture"}
                              />
                            ) : (
                              <img
                                id={"cover-picture-preview"}
                                src={dataCoverPicture}
                                alt={"Cover picture"}
                              />
                            )}
                          </label>
                        </div>
                      </>
                    ) : (
                      <div className="space-y-1 text-center">
                        {!dataCoverPicture && (
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="pictures--upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-skin-600 hover:text-skin-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-skin-500"
                          >
                            {!dataCoverPicture && <span>Upload a file</span>}
                            <input
                              id="pictures--upload"
                              name={"cover_picture"}
                              type={"file"}
                              className={"sr-only"}
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={handleFileUpload}
                            />
                            {dataCoverPicture && (
                              <img
                                id={"cover-picture-preview"}
                                src={dataCoverPicture}
                                alt={"cover picture"}
                                className={"w-80 h-80"}
                              />
                            )}
                          </label>
                          {!dataCoverPicture && (
                            <p className="pl-1">or drag and drop</p>
                          )}
                        </div>
                        {!dataCoverPicture && (
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 10MB
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Photo(s) de présentation
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="presentation--upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-skin-600 hover:text-skin-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-skin-500"
                    >
                      <span>Upload a file</span>
                      <input
                        id="presentation--upload"
                        name={"pictures"}
                        type={"file"}
                        className={"sr-only"}
                        multiple={true}
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleFileUpload}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </div>
            </div>
          </div>
          {dataPictures.length !== 0 && (
            <div className={"mx-4"}>
              <Tab.Group as="div" className="flex flex-col-reverse">
                {/* Image selector */}
                <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-6 gap-6">
                    {dataPictures.map((url) => (
                      <Tab
                        key={url}
                        className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                      >
                        {({ selected }) => (
                          <>
                            {/*<span className="sr-only">{inspiration.data.attributes.title}</span>*/}
                            <span className="absolute inset-0 rounded-md overflow-hidden">
                              <img
                                src={url}
                                alt=""
                                className="w-full h-full object-center object-cover"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected ? "ring-skin-500" : "ring-transparent",
                                "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                  {dataPictures.map((url, index) => (
                    <Tab.Panel key={`${url}__${index}`}>
                      <img
                        id={`previewer-${index}`}
                        src={url}
                        alt={"picture"}
                        className="w-full h-full object-center object-cover sm:rounded-lg"
                      />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          )}
          {inspiration &&
            inspiration.data.attributes.pictures_url.length !== 0 && (
              <div className={"mx-4"}>
                <Tab.Group as="div" className="flex flex-col-reverse">
                  {/* Image selector */}
                  <div className="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                    <Tab.List className="grid grid-cols-6 gap-6">
                      {inspiration.data.attributes.pictures_url.map((url) => (
                        <Tab
                          key={url}
                          className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                        >
                          {({ selected }) => (
                            <>
                              {/*<span className="sr-only">{inspiration.title}</span>*/}
                              <span className="absolute inset-0 rounded-md overflow-hidden">
                                <img
                                  src={url}
                                  alt=""
                                  className="w-full h-full object-center object-cover"
                                />
                              </span>
                              <span
                                className={classNames(
                                  selected
                                    ? "ring-skin-500"
                                    : "ring-transparent",
                                  "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>

                  <Tab.Panels className="w-full aspect-w-1 aspect-h-1">
                    {inspiration.data.attributes.pictures_url.map(
                      (url, index) => (
                        <Tab.Panel key={`${url}__${index}`}>
                          <img
                            id={`previewer-${index}`}
                            src={url}
                            alt={inspiration.title}
                            className="w-full h-full object-center object-cover sm:rounded-lg"
                          />
                        </Tab.Panel>
                      )
                    )}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            )}
          <SelectProducts
            selectedOptions={selectedProducts}
            setSelectedOptions={setSelectedProducts}
          />
        </div>
        <div className="py-5 flex flex-row justify-between">
          <div>
            <span
              onClick={handleDelete}
              className="cursor-pointer ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
            >
              Supprimer le produit
            </span>
          </div>
          <div className="flex justify-end">
            <button
              onClick={goBack}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500"
            >
              Annuler
            </button>
            <button
              disabled={!isLoaded}
              type="submit"
              className={classNames(
                !isLoaded
                  ? "bg-skin-400"
                  : "bg-skin-600 hover:bg-skin-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-500",
                "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white "
              )}
            >
              {isLoaded
                ? isEditMode
                  ? "Sauvegarder les modifications"
                  : "Créer une nouvelle catégorie"
                : "Chargememt de la photo en cours"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminInspirationAddEditPage;

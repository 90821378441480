import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import { Link } from "react-router-dom";
import InspirationItems from "./InspirationItems";
import HeroSection from "../components/HeroSection";
import univers from "../../../assets/images/inspirations.jpg";

const InspirationsPage = () => {
  const livraisonMontageBreadcrumbs = [
    { label: "ADM", link: "/" },
    { label: "services", link: "/services" },
    { label: "livraison & montage", link: "/livraison-montage" },
  ];

  const article = {
    title: "Trouvez l’inspiration !",
    body:
      "<p>Au travers des differentes inspiration que notre équipes à selectionner, inspirez vous et faites de votre projet le projet qui fera la différence.</p>" +
      "<p>Nous sommes en capacité de réaliser chacune d'entre elles.</p>",
    imageURL: univers,
    link: null,
  };

  const inspirationBC = [
    { label: "ADM", link: "/" },
    { label: "Inspirations", link: "/inspirations" },
  ];

  return (
    <GenericLayout title={"Inspirations"}>
      <div className={" xl:mx-24 md:mx-12 mx-4"}>
        <div className="sm:space-y-20 space-y-12">
          <HeroSection
            index={0}
            breadcrumbs={inspirationBC}
            article={article}
          />
        </div>
      </div>

      <div className="mx-4">
        <h2 className="mb-4 sm:mt-24 mt-12 font-freight sm:text-40 text-3xl sm:jusitfy-left justify-center tracking-tight">
          Retrouvez nos dernières inspirations catalogues
        </h2>
      </div>
      <InspirationItems />
    </GenericLayout>
  );
};

export default InspirationsPage;

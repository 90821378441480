import React, { useEffect, useRef } from "react";
import GenericLayout from "../layouts/GenericLayout";
import { gsap } from "gsap";
import hero from "../../../assets/images/vasseur-poster.jpg";
import notreEquipe from "../../../assets/images/notre-equipe.jpg";
import notreHistoire from "../../../assets/images/notre-histoire.jpg";
import nosValeurs from "../../../assets/images/nos-valeurs.jpg";
import notreSavoirFaire from "../../../assets/images/savoir-faire.jpg";
import ReactPlayer from "react-player";

const Presentation = () => {
  const presentationBreadCrumbs = [
    { label: "ADM", link: "/" },
    { label: "Présentation", link: "/presentation" },
  ];

  const presentationPageRef = useRef(null);

  const handleScroll = () => {
    const q = gsap.utils.selector(presentationPageRef);
    gsap.to(q(".imganim"), {
      scale: 1.5,
      duration: 1,
      scrollTrigger: {
        trigger: "imganim",
        scrub: true,
        start: "top bottom",
      },
    });
  };

  return (
    <GenericLayout>
      <div>
        <div className="sm:aspect-w-16 sm:aspect-h-5 aspect-w-1 aspect-h-1  sm:w-5/6 sm:mx-auto mx-4">
          {/*<div*/}
          {/*  className="imgs img-radius bg-cover rounded-lg"*/}
          {/*  style={{ backgroundImage: `url(${hero})` }}*/}
          {/*/>*/}
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=xrgqDZnLbKs"}
            width={"100%"}
            height={"100%"}
            light={hero}
            config={{
              youtube: {
                playerVars: {
                  showInfo: 0,
                },
              },
              file: {
                attributes: {
                  poster: hero,
                },
              },
            }}
          />
        </div>
      </div>
      <p className="lg:w-vw-1/2 lg:mx-auto mx-4 mt-10 text-black font-light font-helvetica sm:text-24 text-16 italic text-justify">
        “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porttitor nunc
        nunc urna, ante dictum eu, et facilisi. Nec et, pharetra id eget
        pharetra tincidunt. Enim dignissim imperdiet tincidunt massa amet
        interdum.”
      </p>
      <p className="font-helvetica sm:text-24 text-16 text-black mt-2 italic sm:text-center mx-4 lg:mb-20 mb-10">
        Famille Vasseur
      </p>
      <div className="bg-skin-500 lg:py-32 py-8">
        <div className="lg:w-vw-5/6 flex lg:flex-row flex-col xl:mx-auto mx-4 justify items-center">
          <div className="flex flex-col lg:w-vw-2/5 justify-end">
            <div className="border-l-2 border-golden-500 xl:pl-8 pl-4">
              <div className="sm:text-center">
                <p className="font-birthstone text-rouge sm:text-56 text-32 sm:mb-0 -mb-4">
                  Notre
                </p>
                <p className="font-fraunces text-black sm:text-56 text-32">
                  Histoire
                </p>
              </div>
              <p className="font-helvetica text-black 2xl:text-20 text-16 text-justify sm:pt-8 pt-2">
                C’est en 1960 que l’aventure « Charcuterie Vasseur » démarre,
                Nous sommes fiers de représenter la 3ème génération, nos grands
                parents avaient une boucherie sur Bruay le Buissière, très
                prisée des mineurs, En 1990, nos pères, Alain et Daniel ce sont
                installés sur Calonne Ricouart, pour accroitre la production, se
                développer davantage. Nous y sommes restés jusqu’en 2020, ou en
                Octobre, une nouvelle usine sur Ruitz a vu naitre le jour,
                Depuis, nous perpétuons les valeurs et les recettes inculquées
                par nos grands-parents, nous sommes une société familiale,
                locale, d’un savoir-faire riche en produits polonais et
                régionaux, La charcuterie Vasseur aime le cochon de sa région.
              </p>
            </div>
          </div>
          <div className="lg:w-vw-1/3 w-full lg:mx-auto mx-4 lg:mt-0 mt-8">
            <div className="aspect-w-6 aspect-h-5 img-radius">
              <div
                className="imgs img-radius bg-cover bg-no-repeat rounded-lg"
                style={{ backgroundImage: `url(${notreHistoire})` }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lg:py-32 py-8">
        <div className="lg:w-vw-5/6 flex lg:flex-row flex-col xl:mx-auto mx-4 justify items-center">
          <div className="flex flex-col lg:w-vw-2/5 justify-end">
            <div className="border-l-2 border-golden-500 xl:pl-8 pl-4">
              <div className="sm:text-center">
                <p className="font-birthstone text-rouge sm:text-56 text-32 sm:mb-0 -mb-4">
                  Notre
                </p>
                <p className="font-fraunces text-black sm:text-56 text-32">
                  Savoir-Faire
                </p>
              </div>
              <p className="font-helvetica text-black 2xl:text-20 text-16 text-justify sm:pt-8 pt-2">
                Notre savoir faire vient de nos grands-parents que nous seuls
                avons le secret,
                <br />
                Nous avons une large gamme de produits polonais, de produits en
                gelée, une gamme de poulet, de patés, de saucissons, et de
                produits festifs,
                <br />
                Pour toutes les saisonnalités, nous sommes présents pour vous
                apporter de bons produits,
                <br />
                Notre charcuterie est essentiellement fabriquées avec de la
                viande saveur en or, c’est à dire de notre région, qui signifie
                pour nous un gage de qualité et de confiance pour le
                consommateur.
                <br />
                Soucieux de toujours vous satisfaire au maximum, nous faisons en
                sorte de vous garantir tous les jours une charcuterie de
                qualité.
                <br />
              </p>
            </div>
          </div>
          <div className="lg:w-vw-1/3 w-full lg:mx-auto mx-4 lg:mt-0 mt-8">
            <div className="aspect-w-6 aspect-h-5 img-radius">
              <div
                className="imgs img-radius bg-cover bg-no-repeat rounded-lg"
                style={{ backgroundImage: `url(${notreSavoirFaire})` }}
              />
            </div>
          </div>
        </div>
      </div>
      ;
      <div className="bg-skin-500 lg:py-32 py-8">
        <div className="lg:w-vw-5/6 flex lg:flex-row flex-col xl:mx-auto mx-4 justify items-center">
          <div className="flex flex-col lg:w-vw-2/5 justify-end">
            <div className="border-l-2 border-golden-500 xl:pl-8 pl-4">
              <div className="sm:text-center">
                <p className="font-birthstone text-rouge sm:text-56 text-32 sm:mb-0 -mb-4">
                  Nos
                </p>
                <p className="font-fraunces text-black sm:text-56 text-32">
                  Valeurs
                </p>
              </div>
              <p className="font-helvetica text-black 2xl:text-20 text-16 text-justify sm:pt-8 pt-2">
                Nous sommes une societé familiale à l’échelle régionale,
                <br />
                Nous sommes certifies IFS, ce qui permet de prouver notre
                maîtrise de la sécurité et l’hygiène de nos produits
                alimentaires transformés
                <br />
                Des tests de dégustation sont régulièrement mis en place au sein
                de notre équipe, afin de s’améliorer et d’apprendre chaque jours
                <br />
                Les viandes avec lesquelles nous travaillons viennent
                essentiellement de notre région, que nous mettons en avant avec
                une gamme de produits régionaux
                <br />
              </p>
            </div>
          </div>
          <div className="lg:w-vw-1/3 w-full lg:mx-auto mx-4 lg:mt-0 mt-8">
            <div className="aspect-w-6 aspect-h-5 img-radius">
              <div
                className="imgs img-radius bg-cover bg-no-repeat rounded-lg"
                style={{ backgroundImage: `url(${nosValeurs})` }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lg:py-32 py-8">
        <div className="lg:w-vw-5/6 flex lg:flex-row flex-col xl:mx-auto mx-4 justify items-center">
          <div className="flex flex-col lg:w-vw-2/5 justify-end">
            <div className="border-l-2 border-golden-500 xl:pl-8 pl-4">
              <div className="sm:text-center">
                <p className="font-birthstone text-rouge sm:text-56 text-32 sm:mb-0 -mb-4">
                  Notre
                </p>
                <p className="font-fraunces text-black sm:text-56 text-32">
                  Equipe
                </p>
              </div>
              <p className="font-helvetica text-black 2xl:text-20 text-16 text-justify sm:pt-8 pt-2">
                Nous sommes une 40 aine a travailler sur le site,
                <br />
                Nous avons un pole fabrication, un pole conditionnement, un pole
                expedition,
                <br />
                Un pole secretariat, un pole qualité et un commerce,
                <br />
                Tous sont en relation dans le but de nous améliorer et de vous
                satisfaire chaque jours,
                <br />
                Nous sommes fiers de nos équipes qui sont rigoureuses,
                travailleuses, ponctuelles, certains ont fait toute leur
                carrière au sein de notre societé
                <br />
              </p>
            </div>
          </div>
          <div className="lg:w-vw-1/3 w-full lg:mx-auto mx-4 lg:mt-0 mt-8">
            <div className="aspect-w-6 aspect-h-5 img-radius">
              <div
                className="imgs img-radius bg-cover bg-no-repeat rounded-lg"
                style={{ backgroundImage: `url(${notreEquipe})` }}
              />
            </div>
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default Presentation;

import React from "react";
import GenericLayout from "../layouts/GenericLayout";
import Api from "../_helpers/api";
import useSWR from "swr";
import ProductGrid from "../components/products/ProductGrid";

const ProductsPage = () => {
  const fetcher = (url) => Api.get(url).then((res) => res.data);
  const { data: products, error } = useSWR("/api/v1/products", fetcher);

  if (!products) {
    return <div>Loading....</div>;
  }
  if (error) {
    return <div>Failed to load </div>;
  }

  return (
    <GenericLayout title={"Produits"}>
      <div className="sm:mx-4">
        <ProductGrid products={products.data} />
      </div>
    </GenericLayout>
  );
};

export default ProductsPage;

import React, { useState } from "react";
import GenericLayout from "../layouts/GenericLayout";
import contact from "../../../assets/images/contact.jpg";
import { useForm } from "react-hook-form";
import { createContact } from "../_services/contact-services";
import classNames from "classnames";

const Contact = () => {
  const [messageSent, setMessageSent] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) =>
    createContact(data, () => {
      setMessageSent(true);
    });

  return (
    <GenericLayout>
      <div className="text-black flex relative sm:flex-row flex-col-reverse items-start justify-between xl:ml-48 sm:ml-4 sm:mr-0 mx-4 my-10 space-x-10 items-end">
        <div className="flex flex-col lg:w-1/2 ">
          <div className="flex flex-col-reverse lg:flex-row gap-4 justify-between">
            <div className="flex-1">
              <h2 className="sm:block hidden mb-2 font-fraunces sm:text-5xl text-4xl text-left">
                Travaillons ensemble
              </h2>
              <p className="lg:mr-48 sm:mt-1 -mt-5 font-grotesque 2xl:text-24 xl:text-18 text-16 text-left">
                Nous aimerions être en contat avec vous ! Utilisez ce formulaire
                pour communiquer avec nous sans vous déplacer, ou envoyez nous
                un e-email nous vous répondrons le plus vite possible :)
              </p>

              <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                <div className="xl:pt-16 md:pt-8">
                  <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Nom*
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="last-name"
                          {...register("lastname")}
                          className="block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Prénom*
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          {...register("firstname")}
                          className="block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        E-mail*
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          {...register("email")}
                          className="block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="phone"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Téléphone
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="phone"
                          {...register("phone_number")}
                          id="phone"
                          autoComplete="tel"
                          className="  block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="nom-entreprise"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Nom de l'entreprise*
                      </label>
                      <div className="mt-1">
                        <input
                          id="nom-entreprise"
                          name="nom-entreprise"
                          type="text"
                          autoComplete=""
                          {...register("company")}
                          className="block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica "
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="help"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Comment pouvons-nous vous aider ?
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="help"
                          name="help"
                          rows="3"
                          {...register("message")}
                          className="block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica bg-white "
                          placeholder=" 500 caractères"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="aboutus"
                        className="block sm:text-24 text-14 font-grotesque text-skin"
                      >
                        Comment avez vous entendu parler de nous ?
                      </label>
                      <div className="mt-1">
                        <select
                          id="aboutus"
                          name="aboutus"
                          defaultValue={null}
                          {...register("how_did_you_know")}
                          className="block w-full border rounded-md focus:ring-2 focus:ring-black outline-none border-golden-500 font-helvetica bg-white"
                        >
                          <option
                            disabled
                            className="text-skin sm:text-20 text-12"
                          >
                            Selectionnez dans la liste
                          </option>
                          <option className="text-skin text-16" value={"salon"}>
                            Salon
                          </option>
                          <option
                            className="text-skin text-16"
                            value={"bouche_a_oreille"}
                          >
                            Bouche à oreille
                          </option>
                          <option
                            className="text-skin text-16"
                            value={"publicite"}
                          >
                            Publicité
                          </option>
                        </select>
                      </div>
                    </div>

                    <fieldset className="sm:col-span-6">
                      <div className="flex">
                        <div className="flex items-center h-5">
                          <input
                            id="consentement"
                            {...register("consent")}
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            className="focus:ring-skin h-4 w-4 text-skin border-skin rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="consentement"
                            className="sm:text-16 text-14 text-skin"
                          >
                            Oui, je donne mon consentement au traitement des
                            données. *
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className={"flex flex-col items-end"}>
                    <button
                      type="submit"
                      disabled={messageSent}
                      className={classNames(
                        { "opacity-30": messageSent },
                        "button-hover inline-flex justify-center mt-6 bg-rouge py-3 sm:px-14 px-10 border border-transparent shadow-sm sm:text-20 text-16 font-medium rounded-md text-white font-helvetica "
                      )}
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
              {messageSent && (
                <div className={"h-full flex flex-col text-xl"}>
                  Votre message est envoyé, nous traitons votre demande et
                  revenons vers vous.
                  <br />
                  l'equipe Vasseur
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="w-full lg:w-1/2 display: flex;
    background-size: cover;
}"
        >
          <div
            className="sm:flex h-full  aspect-ratio-item hidden  aspect-w-1 aspect-h-1 bg-center bg-cover bg-no-repeat rounded-l-lg h-screen"
            style={{ backgroundImage: `url(${contact})` }}
          />
        </div>
      </div>
    </GenericLayout>
  );
};
export default Contact;

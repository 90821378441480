import React from "react";

import GenericLayout from "../layouts/GenericLayout";
import { Link, useParams } from "react-router-dom";
import { useUniverse } from "../_services/universe-services";
import Loading from "../components/utils/Loading";
import Error from "../components/Error";
import { getAllIncludedResourcesForAGivenType } from "../_helpers/jsonapi-deserializer";
import Thumb from "../components/realisations/Thumb";
import _ from "lodash";
import Breadcrumbs from "../components/breadcrumbs";

const UniversePage = () => {
  const { univers_id } = useParams();
  const { universe, isError, isLoading } = useUniverse(univers_id);
  if (isLoading) return <Loading />;
  if (isError) return <Error error={isError} />;
  const { name } = universe.data.attributes;

  console.log(universe.data.attributes.name);

  const realisations = getAllIncludedResourcesForAGivenType(
    universe,
    "realisation"
  );
  const universBC = [
    { label: "ADM", link: "/" },
    { label: "Univers", link: "/univers" },
    { label: `${name}`, link: `/univers/${univers_id}` },
  ];

  return (
    <GenericLayout>
      <h1 className="text-center uppercase sm:text-72 text-24 mt-8 sm:mb-20 mb-10 font-freight stroke-golden-500 tracking-wider">
        {name}
      </h1>

      <div className="xl:mx-48 md:mx-12 mx-4 sm:mb-28">
        <Breadcrumbs breadCrumbs={universBC} />
        <div className={"flex flex-col space-y-4"}>
          {realisations.map((realisation, index) => {
            return (
              <Thumb
                key={_.uniqueId("realisations__")}
                realisation_id={realisation.id}
                isReverse={index % 2 !== 0}
              />
            );
          })}
        </div>
      </div>
    </GenericLayout>
  );
};

export default UniversePage;

import { Link } from "react-router-dom";
import home from "../../../assets/images/homepage.jpg";
import React from "react";
import slugify from "react-slugify";

const CategoryCard = ({ category }) => {
  return (
    <Link
      className={"w-full mb-9 z-10 cursor-pointer col-span-1"}
      to={`/nos-gammes-produits/${category.id}/${slugify(
        category.attributes.name
      )}`}
    >
      <div className="parent img-radius">
        <div className="parent img-radius">
          <div
            className="imgs aspect-w-1 aspect-h-1 img-radius rounded-lg bg-center bg-cover"
            style={{
              backgroundImage: `url(${category.attributes.cover_picture_url})`,
            }}
          />
        </div>
        <div className="text-center xl:mt-4 mt-2">
          <p className="font-fraunces xl:text-32 text-24 text-black font-semibold">
            {category.attributes.name}
          </p>
          <p className="font-helvetica text-16 text-black xl:mx-21">
            {category.attributes.description}{" "}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;

import React from "react";
import styled from "styled-components";

export const NewsBody = ({ content }) => {
  styled.p`
    font-size: 24px;
  `;

  return (
    <div
      className={"font-helvetica news-body"}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

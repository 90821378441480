import React from "react";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useOffsetTop } from "../hooks/useOffsetTop";

const GenericLayout = ({ title, children }) => {
  const offsetTop = useOffsetTop();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/*{title ? (*/}
        {/*  <title>{_.capitalize(title)} | Charcuterie Vasseur</title>*/}
        {/*) : (*/}
        {/*  <title>Charcuterie Vasseur</title>*/}
        {/*)}*/}
        <link rel="canonical" href="https://www.charcuterie-vasseur.com/" />
        <meta
          name="description"
          content="Passionnément charcutier depuis 1960 !"
        />
      </Helmet>
      <NavBar />
      {title && <Header title={title} />}
      <main style={{ paddingTop: `${offsetTop}px` }}>{children}</main>
      <Footer />
    </>
  );
};

export default GenericLayout;

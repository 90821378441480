import React from "react";
import { useCategoriesOptions } from "../../_services/category-services";
import Loading from "../utils/Loading";
import Error from "../Error";
import Select from "react-select";

const SelectCategories = ({ productCategories, setProductCategories }) => {
  const { categories, isLoading, isError } = useCategoriesOptions();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error error={isError} />;
  }

  return (
    <div>
      <Select
        onChange={setProductCategories}
        options={categories.data}
        isMulti
        getOptionLabel={(option) => `${option.attributes.name}`}
        getOptionValue={(option) => `${option.attributes.id}`}
        value={productCategories}
      />
    </div>
  );
};

export default SelectCategories;

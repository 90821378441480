import { API_V1_BASE_URL } from "../_helpers/api";
import useSWR from "swr";
import { getFetcher } from "../_helpers/fetch-wrapper";
import _ from "lodash";

export const useProduct = (id) => {
  const { data, error } = useSWR(
    id ? `${API_V1_BASE_URL}/products/${id}` : null,
    getFetcher
  );
  return {
    product: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useProducts = () => {
  const { data, error } = useSWR(`${API_V1_BASE_URL}/products`, getFetcher);
  return {
    products: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useProductsOptions = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/select_products_options`,
    getFetcher
  );
  return {
    products: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useProductCount = () => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/count_products`,
    getFetcher
  );

  return {
    count: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useSimilarProductsByCategories = (category_ids) => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/similar_products?category_ids[]=${_.join(
      category_ids,
      "&category_ids[]="
    )}`,
    getFetcher
  );

  return {
    products: data,
    isLoading: !error && !data,
    isError: error,
  };
};
export const useProductsByCategory = (category_id) => {
  const { data, error } = useSWR(
    `${API_V1_BASE_URL}/category/${category_id}/products`,
    getFetcher
  );
  if (data) {
    console.log(data);
  }

  return {
    products: data,
    isLoading: !error && !data,
    isError: error,
  };
};

import React, { useEffect, useState } from "react";
import { searchCategories } from "../_services/category-services";
import { Link } from "react-router-dom";

const SearchInput = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (query) {
      searchCategories(query, setResults);
    }

    console.log(results);
  }, [query]);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value) {
      setQuery(e.target.value);
    }
  };

  return (
    <>
      <input
        id="search"
        name="search"
        onChange={handleChange}
        className="h-8 pl-3 border-b border-golden-500 rounded-none w-full pr-3 py-2 rounded-md leading-5 text-base font-fraunces placeholder-black border border-0 bg-transparent focus:ring-transparent placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-golden-500 focus:border-golden-500 sm:text-sm"
        placeholder="Rechercher"
        value={query}
        type="text"
      />
      {results && (
        <div
          className={
            "absolute w-full bg-white mt-8 divide-y divide-golden-400 shadow flex flex-col"
          }
          onClick={() => setResults(null)}
        >
          {results.data.map(({ attributes }) => {
            return (
              <>
                <Link
                  to={`/nos-gammes-produits/${attributes.id}/${attributes.name}`}
                  className={
                    "hover:bg-golden-500 hover:text-golden-50 w-full p-3 font-fraunces"
                  }
                >
                  {attributes.name}
                </Link>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SearchInput;

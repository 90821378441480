import React from "react";
import CategoryCard from "../CategoryCard";
import GenericLayout from "../../layouts/GenericLayout";
import home from "../../../../assets/images/homepage.jpg";
import { useCategories } from "../../_services/category-services";
import Loading from "../utils/Loading";
import Error from "../Error";
import _ from "lodash";

const Categories = () => {
  const { categories, isLoading, isError } = useCategories();
  if (isLoading) return <Loading />;
  if (isError) return <Error />;
  return (
    <GenericLayout>
      <div className="sm:w-5/6 sm:mx-auto mx-4 lg:space-y-24 space-y-12">
        <div
          className={
            "relative lg:aspect-w-16 lg:aspect-h-5 aspect-h-1 aspect-w-1 rounded-lg bg-cover bg-center"
          }
          style={{ backgroundImage: `url(${home})` }}
        />
        <div className={"flex flex-col space-y-12"}>
          <div className="flex justify-between xl:flex-row flex-col">
            <div className="xl:w-vw-1/4 flex flex-col sm:text-center sm:mb-4 xl:mb-0">
              <p className="font-birthstone sm:text-56 text-32 text-rouge z-10">
                Notre
              </p>
              <p className="font-fraunces sm:text-56 text-32 text-black -mt-4 z-10">
                Charcuterie
              </p>
            </div>

            <p className="font-helvetica sm:text-20 text-16 text-black text-justify z-10 xl:w-paragraph">
              Fondée en 1960, la charcuterie Vasseur est une entreprise
              familiale qui se transmet de génération en génération. Nous
              produisons des jambons, saucissons, pâtés et autres spéciliatés
              polonaises. Nous mettons un point d'honneur à respecter les normes
              les plus élevées en matière de qualité et d'hygiène.
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 relative justify-between gap-8">
            {categories.data.map((category) => {
              return (
                <CategoryCard
                  key={_.uniqueId("category__card--")}
                  category={category}
                />
              );
            })}
          </div>
        </div>
      </div>
    </GenericLayout>
  );
};

export default Categories;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const RealisationProductsSlider = ({ products }) => {
  return (
    <div className="flex lg:flex-row flex-col w-full my-5">
      <Swiper
        spaceBetween={15}
        slidesPerView={1.25}
        centeredSlides={true}
        loop={products.length > 3}
        breakpoints={{
          640: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3.5,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 3.25,
            spaceBetween: 30,
            centeredSlides: false,
          },
        }}
        className={"w-full"}
      >
        {products.map(({ attributes }, index) => {
          return (
            <SwiperSlide
              key={`${attributes.id}__${index}`}
              className={"sm:leading-normal leading-tight sm:m-0 mb-8 w-44"}
            >
              <Link to={`/produit/${attributes.id}`} className="">
                <div
                  className={
                    "aspect-w-1 aspect-h-1 rounded-lg bg-cover bg-center"
                  }
                  style={{
                    backgroundImage: `url(${attributes.cover_picture_url})`,
                  }}
                />
                <h2 className="sm:mt-6 mt-2 font-freight sm:text-32 text-24 text-bold ">
                  {attributes.name}
                </h2>
                <p className="font-grotesque text-justify sm:text-left text-xl mr-24 sm:block hidden line-clamp-2">
                  {attributes.description}
                </p>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default RealisationProductsSlider;
